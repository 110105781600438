import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UploadedImageResponse } from 'account-hybrid/common/models/uploaded-image-response';
import { LibraryImage } from 'shared';
import { ImageUploaderService } from 'account-hybrid/common/services/image-uploader.service';

@Injectable(
    { providedIn: 'root' }
)
export class ImageLibraryService {
    imagesMap: { [url: string]: BehaviorSubject<LibraryImage[]> } = {};
    loading$ = new Subject<boolean>();


    constructor(private http: HttpClient) {
    }

    responseToLibraryImage(img: UploadedImageResponse): LibraryImage {
        return { url: img.url, name: img.name, assetId: img.id };
    }

    loadAll(url: string): BehaviorSubject<LibraryImage[]> {
        if (this.imagesMap[url]) {
            return this.imagesMap[url];
        } else {
            this.loading$.next(true);
            this.imagesMap[url] = new BehaviorSubject([]);

            this.http.get(url).pipe(
                map(({ asset_images }: { asset_images: UploadedImageResponse[] }) => {
                    return asset_images.map(img => this.responseToLibraryImage(img));
                }),
                tap((images) => {
                    this.imagesMap[url].next(images);
                })
            ).toPromise().finally(() => {
                this.loading$.next(false);
            });
            return this.imagesMap[url];
        }
    }

    uploadImageFromUrl(externalImageUrl: string, uploadUrl: string): Promise<LibraryImage> {
        this.loading$.next(true);
        const params: any = {
            url: externalImageUrl
        };
        return this.http.post(uploadUrl, {}, { params }).pipe(
            map((img: UploadedImageResponse) => this.responseToLibraryImage(img))
        ).toPromise().finally(() => this.loading$.next(false));
    }

    uploadImageFromFile(file: any, url: string): Promise<LibraryImage> {
        this.loading$.next(true);
        return this.http.post(url, ImageUploaderService.fileToFormData(file)).pipe(
            map((img: UploadedImageResponse) => this.responseToLibraryImage(img))
        ).toPromise().finally(() => this.loading$.next(false));
    }
}
