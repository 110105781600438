import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { ThemingComponent } from './components/theming/theming.component';
import { JourneysComponent } from './components/journeys/journeys.component';
import { TypographyComponent } from './components/typography/typography.component';
import { BemComponent } from './components/bem/bem.component';
import { InputsComponent } from './components/inputs/inputs.component';
import { SelectsComponent } from './components/selects/selects.component';
import { HomeComponent } from './components/home/home.component';
import { PanelsComponent } from './components/panels/panels.component';
import { EditorsComponent } from './components/editors/editors.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SnapshotComponent } from './components/snapshot/snapshot.component';
import { D3Component } from './components/d3/d3.component';
import { TreePageComponent } from './components/tree-page/tree-page.component';
import { ImagesPageComponent } from './components/images-page/images-page.component';


const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'panels',
        component: PanelsComponent
    },
    {
        path: 'buttons',
        component: ButtonsComponent
    },
    {
        path: 'inputs',
        component: InputsComponent
    },
    {
        path: 'selects',
        component: SelectsComponent
    },
    {
        path: 'bem',
        component: BemComponent
    },
    {
        path: 'typography',
        component: TypographyComponent
    },
    {
        path: 'theming',
        component: ThemingComponent
    },
    {
        path: 'journeys',
        component: JourneysComponent
    },
    {
        path: 'editors',
        component: EditorsComponent
    },
    {
        path: 'datepicker',
        component: DatepickerComponent
    },
    {
        path: 'snapshot',
        component: SnapshotComponent
    },
    {
        path: 'd3',
        component: D3Component
    },
    {
        path: 'tree',
        component: TreePageComponent
    },
    {
        path: 'images',
        component: ImagesPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class StyleguideRoutingModule {
}
