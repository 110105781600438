import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.scss']
})
export class PanelsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }



}
