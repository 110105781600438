import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class SeasonsService {
    seasons = ['Winter', 'Spring', 'Summer', 'Fall'];

    constructor() {
    }

    getSeason(increment = 0) {
        const season = this.getFromMonth(new Date().getMonth());
        if (increment) {
            if (increment < 0) {
                increment = (increment % this.seasons.length) + this.seasons.length;
            }
            const incrementIndex = (this.seasons.indexOf(season) + increment) % this.seasons.length;
            return this.seasons[incrementIndex];
        }
        return season;
    }

    getFromMonth(month: number) {
        if (month >= 3 && month <= 5) {
            return this.seasons[1];
        }

        if (month >= 6 && month <= 8) {
            return this.seasons[2];
        }

        if (month >= 9 && month <= 11) {
            return this.seasons[3];
        }
        return this.seasons[0];
    }
}
