<h1 class="display-2">
    Typography
</h1>

<section class="sg-section">
    <h2 class="h3">Base settings</h2>
    <p>font-size: 16px.</p>
    <p>font-weight: 300.</p>
    <p>line-height: 1.6.</p>
    <p>color: #222.</p>
</section>

<section class="sg-section">
    <h2 class="h3">Fonts</h2>
    <div class="row">
        <div class="col-6">
            <h3 class="h5 strong-text">Museo Sans</h3>
            <p>
                300 for the majority of text including heading, body, captions, etc.
            </p>
            <p>
                <b>500</b> for buttons and links, plus sometimes headings or light emphasis of blocks of copy. Class -
                <span class="text-secondary">.bold-text</span>
            </p>
            <p>
                <strong>700</strong> to emphasize specific words or phrases in copy. Class -
                <span class="text-secondary">.strong-text</span>
            </p>
            <h3 class="h5 solid-text mt-5">Brandon grotesque</h3>
            <p>We use this font only in bold and UPPERCASE. Typically set at 13px for supertitles and labels. Sometimes
                it
                is used in larger sizes for display titles. For other general use, should only be applied to an element
                with
                few words.
            </p>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">Sizes</h2>
    <div class="row">
        <div class="col-6">
            <p>
                We have size-helpers classes to change regular text font-size:
            </p>
            <br>
            <p>
                <code>.tiny-text</code> class - 10px
                <span class="tiny-text">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.small-text</code> class or <code>&lt;small&gt;</code> tag - 12px
                <span class="small-text">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.medium-text</code> class - 14px
                <span class="medium-text">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.base-text</code> class (default value) - 16px
                <span class="base-text">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.large-text</code> class - 18px
                <span class="large-text">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.huge-text</code> class - 24px
                <span class="huge-text">Lorem ipsum dolor sit amet.</span>
            </p>
        </div>
    </div>
</section>
<section class="sg-section">
    <div class="row">
        <div class="col-6 mb-5">
            <h2 class="h3">Headers</h2>
            <p>All HTML headings, <code>&lt;h1&gt;</code> through <code>&lt;h6&gt;</code>,
                are available.
            </p>
            <p>
                <code>.h1</code> through <code>.h6</code> classes are also available, for when you want to match the
                font styling of a heading but cannot use the associated HTML element.
            </p>
        </div>
    </div>
    <h1 class="h1">
        Header 1
        <span class="muted-text base-text">3rem or 48px</span>
    </h1>
    <h2 class="h2">
        Header 2
        <span class="muted-text base-text">2.625rem or 42px</span>
    </h2>
    <h3 class="h3">
        Header 3
        <span class="muted-text base-text">2.25rem or 36px</span>
    </h3>
    <h4 class="h4">
        Header 4
        <span class="muted-text base-text">2rem or 32px</span>
    </h4>
    <h5 class="h5">
        Header 5
        <span class="muted-text small-text">1.5rem or 24px</span>
    </h5>
    <h6 class="h6">
        Header 6
        <span class="muted-text small-text">1.125rem or 18px</span>
    </h6>
</section>
<section class="sg-section">
    <div class="row">
        <div class="col-6 mb-5">
            <h2 class="h3">Display Headers</h2>
            <p>
                According to bootstrap - traditional heading elements are designed to work best in the meat of your page
                content. When you need a
                heading to stand out, consider using a display heading—a larger, slightly more opinionated heading
                style.
            </p>
        </div>
    </div>
    <h1 class="display-1">
        Display 1
        <span class="muted-text base-text">6rem or 96px</span>
    </h1>
    <h2 class="display-2">
        Display 2
        <span class="muted-text base-text">4.5rem or 72px</span>
    </h2>
    <h3 class="display-3">
        Display 3
        <span class="muted-text base-text">4rem or 64px</span>
    </h3>
    <h4 class="display-4">
        Display 4
        <span class="muted-text base-text">3.5rem or 56px</span>
    </h4>
</section>
<section class="sg-section">
    <h2 class="h3">Decorations</h2>
    <div class="row">
        <div class="col-6">
            <p>
                Styling for common inline HTML5 elements and popular CSS text rules.
            </p>
            <br>
            <p>
                <code>.uppercase</code> class -
                <span class="uppercase">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.underline</code> class -
                <span class="underline">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>.strike-through</code> class -
                <span class="strike-through">Lorem ipsum dolor sit amet.</span>
            </p>
            <p>
                <code>&lt;em&gt;</code> and <code>&lt;i&gt;</code> tags -
                <em>Lorem ipsum dolor sit amet.</em>
            </p>
            <p>
                <code>&lt;strong&gt;</code> and <code>&lt;b&gt;</code> tags -
                <strong>Lorem ipsum dolor sit amet.</strong> and <b>Lorem ipsum dolor sit amet.</b>
            </p>
        </div>
    </div>
</section>
