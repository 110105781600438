import { Pipe, PipeTransform } from '@angular/core';
import { val } from '@uirouter/core';

@Pipe({
  name: 'ftPlaceholder'
})
export class PlaceholderPipe implements PipeTransform {

  transform(value: any, text: string = 'Please provide value'): any {
    return typeof value === 'undefined' || value === null || value === '' || value?.length === 0 ? text : value;
  }
}
