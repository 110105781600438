import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CdkDrag, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
    selector: 'sg-tree-page',
    templateUrl: './tree-page.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TreePageComponent implements OnInit {
    data = [
        {
            name: 'Good destinations',
            id: 's1',
            destinations: [
                {
                    name: 'Myrtle Beach',
                    beUrl: 'www.google.ru/',
                    beCode: 'SAD3213',
                    greetingMessage: 'Hello',
                    id: 'd1',
                    seasonPhotos: [
                        {
                            name: 'Winter',
                            photos: [
                                'https://upload.wikimedia.org/wikipedia/commons/7/72/Snow_Scene_at_Shipka_Pass_1.JPG',
                                'https://cdn.mos.cms.futurecdn.net/2LVWjEV65antgXRgEAXJRb-1200-80.jpg'
                            ]
                        },
                        {
                            name: 'Summer',
                            photos: [
                                'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/20190212_SKJ0426-HDR-2.jpg/1200px-20190212_SKJ0426-HDR-2.jpg',
                                'https://cdn.abcotvs.com/dip/images/5356755_062119-cc-ss-summer-sun-img.jpg'
                            ]
                        }
                    ],
                    properties: [
                        {
                            name: 'Avista Resort'
                        },
                        {
                            name: 'Beach Cover Resort'
                        }
                    ]
                },
                {
                    name: 'Myrtle Beach North',
                    id: 'd2',
                    beUrl: 'www.google.ru/',
                    beCode: 'SAD3213',
                    greetingMessage: 'Hello',
                    seasonPhotos: [],
                    properties: [
                        {
                            name: 'Sands Ocean club'
                        },
                        {
                            name: 'Paradise Resort'
                        },
                        {
                            name: 'The Strand'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Best destinations',
            id: 's2',

            destinations: [
                {
                    name: 'Orlando Bound',
                    id: 'd3',
                    seasonPhotos: [],
                    beUrl: 'www.google.ru/',
                    beCode: 'SAD3213',
                    greetingMessage: 'Hello',
                    properties: [
                        {
                            name: 'Avanti Resort'
                        },
                        {
                            name: 'Buena Vista Resort'
                        },
                        {
                            name: 'Galleria Palms Hotel'
                        }
                    ]
                }
            ]
        }
    ];
    seeds = [];
    seedType: number;
    sectionsIds: string[];
    destinationsIds: string[];
    getDestinationSeed = this.createDestinationSeed();
    getPropertySeed = this.createPropertySeed();
    getSectionSeed = this.createSectionSeed();


    constructor() {
    }

    ngOnInit(): void {
        this.refreshConnections();
    }

    drop(event: any) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
        }
        this.refreshConnections();
    }

    refreshConnections() {
        this.sectionsIds = this.data.map(({ id }) => id);
        this.destinationsIds = this.data.reduce((prev, { destinations }) => [...prev, ...(destinations as any[]).map(({ id }) => id)], []);
    }

    getSeedConnections() {
        if (this.seedType === 1) {
            return ['ft-tree'];
        }

        if (this.seedType === 2) {
            return this.sectionsIds;
        }

        if (this.seedType === 3) {
            return this.destinationsIds;
        }
    }

    removeNode(node: any) {
        this.data = this.data.filter((s) => {
            s.destinations = s.destinations.filter((d) => {
                d.properties = d.properties.filter(p => p !== node);
                return d !== node;
            });
            return s !== node;
        });
    }

    addSection() {
        this.seedType = 1;
        this.seeds.push(this.getSectionSeed());
    }


    addProperty() {
        this.seedType = 3;
        this.seeds.push(this.getPropertySeed());
    }

    addDestination() {
        this.seedType = 2;
        this.seeds.push(this.getDestinationSeed());
    }

    createDestinationSeed() {
        let id = 0;
        return () => ({
            name: 'Unnamed destination',
            beUrl: '',
            beCode: '',
            greetingMessage: '',
            seasonPhotos: [],
            id: 'destination-seed-' + id++,
            properties: []
        });
    }

    createPropertySeed() {
        let id = 0;
        return () => ({
            name: 'Select Property',
            beCode: '',
            id: 'property-seed-' + id++
        });
    }

    createSectionSeed() {
        let id = 0;
        return () => ({
            name: 'Unnamed Section',
            id: 'section-seed-' + id++,
            destinations: []
        });
    }
}
