<div class="ft2-bg-striped py-6 mb-5" *ngIf="totalChanges > 0 || hasChanges">
    <div class="ft2-container">
        <div class="row">
            <div class="col-8">
                <h2 class="h5 bold-text">Confirm your changes.</h2>
                <p>
                    <ng-container *ngIf="totalChanges > 1; else singleChange">
                        You have {{totalChanges}} changes pending.
                    </ng-container>
                    <ng-template #singleChange>
                        You have pending changes.
                    </ng-template>
                    If everything looks good, save &amp; publish to apply
                    all changes.
                </p>
            </div>
            <div class="col-4 h-end">
                <button class="ft2-btn-shade -large mr-2" (click)="cancel.next()">
                    Cancel all
                </button>
                <button class="ft2-btn-primary -large" (click)="commit.next()" [ftLoading]="loading$">
                    Save &amp; publish
                </button>
            </div>
        </div>
    </div>
</div>
