<h1 class="display-2">
    Selects
</h1>
<div class="text-content">
    <p>
        We are using ng-select lib. To read more about ng-select features you can here:
        <a class="text-secondary" href="https://ng-select.github.io/ng-select" target="_blank">
            https://ng-select.github.io/ng-select
        </a>
    </p>
</div>
<section class="sg-section">
    <h3 class="h3">
        Types
    </h3>
    <div class="row">
        <div class="col-3">
            <ng-select
                    class="ft2-select"
                    placeholder="ngSelect"
                    [clearable]="true"
                    [searchable]="true"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-3">
            <ng-select class="ft2-select"
                       [disabled]="true"
                       [(ngModel)]="value"
                       placeholder="ngSelect disabled"
                       [clearable]="false"
                       [searchable]="false"
                       [items]="items">
            </ng-select>
        </div>
        <div class="col-3">
            <select class="ft2-select">
                <option disabled selected>Default select</option>
                <option>One</option>
                <option>Two</option>
                <option>Three</option>
            </select>
        </div>
        <div class="col-3">
            <select disabled class="ft2-select">
                <option disabled selected>Default select disabled</option>
                <option>One</option>
                <option>Two</option>
                <option>Three</option>
            </select>
        </div>
    </div>
</section>
<section class="sg-section">
    <h3 class="h3">
        Theme
    </h3>
    <div class="row">
        <div class="col-6">
            <ng-select
                    class="ft2-select"
                    placeholder="Light"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-6">
            <ng-select
                    class="ft2-select -dark"
                    placeholder="Dark"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
    </div>
</section>
<section class="sg-section">
    <h3 class="h3">
        Shape
    </h3>
    <div class="row">
        <div class="col-3">
            <ng-select
                    class="ft2-select -round"
                    placeholder="Round"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-3">
            <ng-select
                    class="ft2-select -radius"
                    placeholder="Radius"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-3">
            <ng-select
                    class="ft2-select -square"
                    placeholder="Square"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-3">
            <ng-select
                    class="ft2-select -pure"
                    placeholder="Pure"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
    </div>
</section>
<section class="sg-section">
    <h3 class="h3">
        Size
    </h3>
    <div class="row">
        <div class="col-4">
            <ng-select
                    class="ft2-select -large"
                    placeholder="Large"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-4">
            <ng-select
                    class="ft2-select -medium"
                    placeholder="Medium"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-4">
            <ng-select
                    class="ft2-select -small"
                    placeholder="Small"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
    </div>
</section>
<section class="sg-section">
    <h3 class="h3">
        Positioning
    </h3>
    <div class="row">
        <div class="col-4">
            <ng-select
                    class="ft2-select -left"
                    placeholder="Left"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-4">
            <ng-select
                    class="ft2-select -center"
                    placeholder="Center"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-4">
            <ng-select
                    class="ft2-select -right"
                    placeholder="Right"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
    </div>
</section>
<section class="sg-section">
    <h3 class="h3">
        Multiple
    </h3>
    <div class="row">
        <div class="col-6">
            <ng-select
                    class="ft2-select -left"
                    placeholder="Multiple"
                    [multiple]="true"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="items">
            </ng-select>
        </div>
        <div class="col-6">
            <ng-select
                    class="ft2-select -left"
                    placeholder="Adding custom value"
                    [addTag]="true"
                    [multiple]="true"
                    [clearable]="false"
                    [searchable]="true"
                    [items]="items">
            </ng-select>
        </div>
    </div>
</section>
