import { NgModule } from '@angular/core';
import { ValidationChecklistComponent } from './validation-checklist.component';
import { CommonModule } from '@angular/common';
import { ChecklistSaveComponent } from './components/checklist-save/checklist-save.component';
import { LoadingModule } from '../loading/loading.module';


@NgModule({
    imports: [
        CommonModule,
        LoadingModule
    ],
    declarations: [
        ValidationChecklistComponent,
        ChecklistSaveComponent
    ],
    exports: [
        ValidationChecklistComponent,
        ChecklistSaveComponent
    ]
})
export class ValidationChecklistModule {
}
