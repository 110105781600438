<h1 class="display-2">
    Editors
</h1>
<div class="sg-container">
    <section class="sg-section">
        <h2 class="h3">
            Color editor
        </h2>
        <h3 class="h5">
            Examples
        </h3>
        <ft-color-editor title="Color picker" [(ngModel)]="color">
            {{color}}
        </ft-color-editor>
    </section>
    <section class="sg-section">
        <h2 class="h4">
            Base options
        </h2>
        <table class="sg-table">
            <tr>
                <td>
                    <code>[(ngModel)]</code>
                </td>
                <td>
                    two way data binding
                </td>
            </tr>
            <tr>
                <td>
                    <code>ng-content</code>
                </td>
                <td>
                    to provide trigger element of editor
                </td>
            </tr>
            <tr>
                <td>
                    @Input or TemplateRef <code>title: string</code>
                </td>
                <td>
                    to provide editor's title
                </td>
            </tr>
            <tr>
                <td>
                    @Input or TemplateRef <code>summary: string</code>

                </td>
                <td>
                    to provide small description of editor
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>buttonLabel: string</code>

                </td>
                <td>
                    to provide text of action button
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>required: boolean</code>

                </td>
                <td>
                    validate if value is required
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>controlName: string</code>
                </td>
                <td>
                    name attribute to declare control in form
                </td>
            </tr>

        </table>
        <h2 class="h5">
            Example
        </h2>

        <ft-input-editor title="Editor title"
                         [required]="true"
                         [name]="'test'"
                         [saveSuccess$]="saveSuccess$"
                         (save)="onSave($event)">
            <ng-template #summary>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium beatae dolor doloremque ducimus
                    esse eveniet excepturi facere.
                </p>
            </ng-template>
            <button class="ft2-btn-primary">
                Open editor
            </button>
        </ft-input-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Input editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>placeholder</code>
                </td>
                <td>
                    input placeholder
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>email: boolean</code>
                </td>
                <td>
                    validate value as an email
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>maxlength: number</code>
                </td>
                <td>
                    validate max length of text
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>minlength: number</code>
                </td>
                <td>
                    validate min length of text
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>max: number</code>
                </td>
                <td>
                    validate max value if input type <code>number</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>min: number</code>
                </td>
                <td>
                    validate min value if input type <code>number</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>pattern: string</code>
                </td>
                <td>
                    validate matching of RegEx
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>patternValidationText: string</code>
                </td>
                <td>
                    error text of <code>pattern</code> validation
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>type: InputFieldType</code>
                </td>
                <td>
                    type of input (<code>text</code> | <code>number</code> | <code>email</code>
                    | <code>url</code>
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Notes
        </h3>
        <p>
            If you don't use [(ngModel)], you should always pass @Input <code>name</code>, and get your changes
            from @Output <code>Save</code> in format <code>controlName: value</code>
        </p>
        <h3 class="h5">
            Examples
        </h3>
        <ft-input-editor title="Input editor"
                         [(ngModel)]="text"
                         [minlength]="40"
                         required
                         [name]="'test'"
                         (save)="onSave($event)">
            <ft-edit-panel>
                <p>{{text}}</p>
            </ft-edit-panel>
        </ft-input-editor>
        <ft-input-editor title="Edit email"
                         [name]="'email'"
                         [placeholder]="'Enter your email'"
                         (save)="email = $event.email"
                         [email]="true">
            <ft-edit-panel label="Email">
                {{email ? email : 'Please provide value'}}
            </ft-edit-panel>
        </ft-input-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Textarea editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>placeholder</code>
                </td>
                <td>
                    input placeholder
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>maxlength: number</code>
                </td>
                <td>
                    validate max length of text
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>minlength: number</code>
                </td>
                <td>
                    validate min length of text
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>max: number</code>
                </td>
                <td>
                    validate max value if input type <code>number</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>min: number</code>
                </td>
                <td>
                    validate min value if input type <code>number</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>pattern: string</code>
                </td>
                <td>
                    validate matching of RegEx
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>patternValidationText: string</code>
                </td>
                <td>
                    error text of <code>pattern</code> validation
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>type: 'url'</code>
                </td>
                <td>
                    url validation
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>tokens: EditorToken[]</code>
                </td>
                <td>
                    Array of tokens, which can be inserted in textarea via select above
                    it. EditorToken: <code>name: string; value: string;</code>
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-textarea-editor title="Textarea editor"
                            [placeholder]="'Please provide url'"
                            [(ngModel)]="url"
                            [tokens]="bookingEngineUrlTokens"
                            [type]="'url'"
                            required
                            [name]="'test'"
                            (save)="onSave($event)">
            <ft-edit-panel>
                <p>{{url | ftPlaceholder: 'Enter Url'}}</p>
            </ft-edit-panel>
        </ft-textarea-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Select editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>placeholder: string</code>
                </td>
                <td>
                    select placeholder
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>options: any[]</code>
                </td>
                <td>
                    select options
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindLabel: string</code>
                </td>
                <td>
                    label of select option
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindValue: string</code>
                </td>
                <td>
                    value of select option
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-select-editor title="Select editor"
                          required
                          [placeholder]="'Select role'"
                          [name]="'role'"
                          (save)="onSelect($event)"
                          [options]="options"
                          [bindLabel]="'role'"
                          [bindValue]="'id'">
            <ft-edit-panel>
                Please select role:
            </ft-edit-panel>
        </ft-select-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Toggle editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>label: string</code>
                </td>
                <td>
                    toggle label
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-toggle-editor [(ngModel)]="toggled" title="Toggle editor" [label]="'Activate'">
            <ft-edit-panel>
                {{toggled ? 'Activated' : 'Deactivated'}}
            </ft-edit-panel>
        </ft-toggle-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            List editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <p>
            List editors has same options and validators as Input editor as well as:
        </p>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>allowDuplicates: boolean</code>
                </td>
                <td>
                    allows to add item with same name <code>(default: false)</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>caseSensitive: boolean</code>
                </td>
                <td>
                    works only with <code>allowDuplicates = false</code> disallows to
                    add item with same name and case <code>(default: false)</code>
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-list-editor title="List editor" [(ngModel)]="textArray" [type]="'email'" [placeholder]="'Add value'" [allowDuplicates]="false" [caseSensitive]="true">
            <ft-edit-panel>
                {{textArray}}
            </ft-edit-panel>
        </ft-list-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Code editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>language: 'html' | 'css' | 'json' | 'javascript' | 'typescript'</code>
                </td>
                <td>
                    language of editor
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>height: 'number''</code>
                </td>
                <td>
                    height of editor
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-code-editor title="Code editor"
                        required
                        [(ngModel)]="html"
                        [language]="'html'">
            <ft-edit-panel label="html">
                <div class="col-8">
                    <p>{{html}}</p>
                </div>
            </ft-edit-panel>
        </ft-code-editor>
        <ft-code-editor title="Code editor"
                        [(ngModel)]="css"
                        [language]="'css'">
            <ft-edit-panel label="css">
                <p>{{css}}</p>
            </ft-edit-panel>
        </ft-code-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Html editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>language: EditorLanguage'</code>
                </td>
                <td>
                    language of editor
                    <code>'html' | 'css' | 'json' | 'javascript' | 'typescript'</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>height: 'number''</code>
                </td>
                <td>
                    height of editor
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>fields: EditorField[]</code>
                </td>
                <td>
                    requires when editor should have more then 1 field. EditorField:
                    <code>name: string; label: string;</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>tokens: EditorToken[]</code>
                </td>
                <td>
                    Array of tokens, which can be inserted in input via select above
                    it. EditorToken: <code>name: string; value: string;</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>maxlength: number</code>
                </td>
                <td>
                    validate max length of text
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>minlength: number</code>
                </td>
                <td>
                    validate min length of text
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-html-editor title="Html editor"
                        [(ngModel)]="html">
            <ft-edit-panel label="Simple HTML Editor">
                <div [innerHTML]="html"></div>
            </ft-edit-panel>
        </ft-html-editor>
        <ft-html-editor title="Html editor with fields"
                        [fields]="[{name: 'greeting', label: 'Provide greeting message'}, {name: 'parting', label: 'Provide parting message'}]"
                        [(ngModel)]="textWithFields">
            <ft-edit-panel label="Html editor with fields">
                <div class="d-flex">
                    <div class="mr-5">Greeting:
                        <div [innerHTML]="textWithFields.greeting"></div>
                    </div>
                    <div>Parting:
                        <div [innerHTML]="textWithFields.parting"></div>
                    </div>
                </div>
            </ft-edit-panel>
        </ft-html-editor>
        <ft-html-editor title="Html editor"
                        [fields]="[{name: 'greeting', label: 'Provide greeting message'}]"
                        [tokens]="[{name: 'Hotel name', value: '{hotel}'}, {name: 'Guest name', value: '{guest}'}]"
                        [(ngModel)]="multiText">
            <ft-edit-panel label="HTML multi-language editor with fields">
                <div class="d-flex">
                    <div *ngFor="let text of multiText" class="mr-5">{{text.language}}:
                        <div [innerHTML]="text.greeting"></div>
                    </div>
                </div>
            </ft-edit-panel>
        </ft-html-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Date range editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>rangeLimit: number</code>
                </td>
                <td>
                    limit date range in days
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>futureDisabled: boolean</code>
                </td>
                <td>
                    disallow to select date from future <code>(default:
                    false)</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>pastDisabled: boolean</code>
                </td>
                <td>
                    disallow to select date from past <code>(default: false)</code>
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-daterange-editor [(ngModel)]="newDateRange" [rangeLimit]="50" (save)="onSave($event)">
            <ft-edit-panel label="New date range">
                <div *ngIf="newDateRange">
                    {{newDateRange?.fromDate | date}} - {{newDateRange?.toDate | date}}
                </div>
                <div *ngIf="!newDateRange">
                    Please provide date range
                </div>
            </ft-edit-panel>
        </ft-daterange-editor>
        <br>
        <ft-daterange-editor [(ngModel)]="dateRange" [futureDisabled]="true">
            <ft-edit-panel label="Change date range">
                <div>
                    {{dateRange.fromDate | date}} - {{dateRange.toDate | date}}
                </div>
            </ft-edit-panel>
        </ft-daterange-editor>
        <br>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Radio editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>options: any[]</code>
                </td>
                <td>
                    array of text or objects
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindLabel: string</code>
                </td>
                <td>
                    object property to show in label
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindValue: string</code>
                </td>
                <td>
                    object property to for returning value
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindSummary: string</code>
                </td>
                <td>
                    object property to show as summary under the label
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-radio-editor [name]="'number'"
                         [title]="'Select number'"
                         required
                         [options]="textArray"
                         (save)="onSave($event)">
            <ft-edit-panel label="Simple text array">
                Select number
            </ft-edit-panel>
        </ft-radio-editor>
        <br>
        <ft-radio-editor [(ngModel)]="selectedRole"
                         [title]="'Select role'"
                         [options]="options"
                         [bindValue]="'id'"
                         [bindLabel]="'role'"
                         [bindSummary]="'description'">
            <ft-edit-panel label="Objects array">
                {{selectedRole ? selectedRole : 'Select role'}}
            </ft-edit-panel>
        </ft-radio-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Checkbox editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>options: any[]</code>
                </td>
                <td>
                    array of text or objects
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindLabel: string</code>
                </td>
                <td>
                    object property to show in label
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindValue: string</code>
                </td>
                <td>
                    object property to for returning value
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>bindSummary: string</code>
                </td>
                <td>
                    object property to show as summary under the label
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-checkbox-editor [options]="textArray"
                            [title]="'Select numbers'"
                            [(ngModel)]="textArraySelected"
                            (save)="onSave($event)">
            <ft-edit-panel label="Simple text array">
                {{textArraySelected}}
            </ft-edit-panel>
        </ft-checkbox-editor>
        <br>
        <ft-checkbox-editor [(ngModel)]="selectedRoles"
                            [title]="'Select roles'"
                            [required]="true"
                            [options]="options"
                            [bindValue]="'id'"
                            [bindLabel]="'role'"
                            [bindSummary]="'description'">
            <ft-edit-panel label="Objects array">
                {{selectedRoles.length ? selectedRoles : 'Select roles'}}
            </ft-edit-panel>
        </ft-checkbox-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Wizard editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>initialStepIndex: number</code>
                </td>
                <td>
                    index of step to show in wizard while opening (default: 0)
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>singleStep: boolean</code>
                </td>
                <td>
                    set to <code>true</code> if you want to make changes only in
                    specific step (default: false)
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-wizard-editor
                [initialStepIndex]="0"
                (save)="onSave($event)">
            <ng-template #step>
                <div class="ft2-edit-dialog_header">
                    First name
                </div>
                <p class="muted-text mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
                <input type="text" class="ft2-input -large" name="firstName" [(ngModel)]="firstName" required>
            </ng-template>
            <ng-template #step>
                <div class="ft2-edit-dialog_header">
                    Last name
                </div>
                <p class="muted-text mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
                <input type="text" class="ft2-input -large" name="lastName" [(ngModel)]="lastName" required>
            </ng-template>
            <ft-edit-panel label="Wizard name">
                {{firstName}} {{lastName}}
            </ft-edit-panel>
        </ft-wizard-editor>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            Image library editor
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>getUrl: string</code>
                </td>
                <td>
                    url to get all available image to select
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>uploadFromUrl: string</code>
                </td>
                <td>
                    url to upload an image from external url
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>uploadUrl: string</code>
                </td>
                <td>
                    url to upload an image
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>accept: string</code>
                </td>
                <td>
                    accepted formats for upload images (default: '.jpg, .jpeg, .png, .gif')
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>activeTabIndex: number</code>
                </td>
                <td>
                    Define active tab when open editor (1 - library, 2 - upload, 3 - image url)
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>preview: boolean</code>
                </td>
                <td>
                    to show preselected image
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>outputAsset: boolean</code>
                </td>
                <td>
                    by default editor returns only image url (string), if outputAsset = true, editor will return asset
                    image (url: string; name: string; assetId?: string);
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>removable: boolean</code>
                </td>
                <td>
                    to add remove button to editor
                </td>
            </tr>
            <tr>
                <td>
                    @Output <code>remove: EventEmitter(LibraryImage)</code>
                </td>
                <td>
                    output remove event of image (url: string; name: string; assetId?: string)
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <ft-image-library-editor [(ngModel)]="img">
            <ft-edit-panel label="Image library">
                Image
            </ft-edit-panel>
        </ft-image-library-editor>
    </section>
</div>
