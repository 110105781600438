import {
    Component,
    Input,
    forwardRef,
    OnInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorField } from '../../../models/editor-field';
import { EditorToken } from '../../../models/editor-token';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';


@Component({
    selector: 'ft-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => HtmlEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: HtmlEditorComponent
        }
    ]
})
export class HtmlEditorComponent extends ModalEditorBase {
    @Input() fields: EditorField[];
    @Input() tokens: EditorToken[];
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() email: boolean;
    @Input() pattern: string;
    @Input() plain = false;
    isArray = false;

    constructor() {
        super();
    }

    isArrayValue() {
        return Array.isArray(this.value);
    }
}
