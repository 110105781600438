import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSelectModule } from '@angular/material/select';
import { PlaceholderPipe } from './pipes/placeholder.pipe';
import { MaxValidatorDirective } from './directives/max-validator.directive';
import { MinValidatorDirective } from './directives/min-validator.directive';
import { EditPanelComponent } from './components/edit-panel/edit-panel.component';
import { EditPanelsService } from './services/edit-panels.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InputEditorComponent } from './components/editors/input-editor/input-editor.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { CustomEditorComponent } from './components/editors/custom-editor/custom-editor.component';
import { HtmlEditorComponent } from './components/editors/html-editor/html-editor.component';
import { WizardEditorComponent } from './components/editors/wizard-editor/wizzard-editor.component';
import { CodeEditorComponent } from './components/editors/code-editor/code-editor.component';
import { SelectEditorComponent } from './components/editors/select-editor/select-editor.component';
import { TextHtmlEditorComponent } from './components/editors/text-html-editor/text-html-editor.component';
import { ToggleEditorComponent } from './components/editors/toggle-editor/toggle-editor.component';
import { ListEditorComponent } from './components/editors/list-editor/list-editor.component';
import { DaterangeEditorComponent } from './components/editors/daterange-editor/daterange-editor.component';
import { DateRangePickerModule } from '../date-range-picker/date-range-picker.module';
import { RadioEditorComponent } from './components/editors/radio-editor/radio-editor.component';
import { CheckboxEditorComponent } from './components/editors/checkbox-editor/checkbox-editor.component';
import { DropzoneUploaderEditorComponent } from './components/editors/dropzone-uploader-editor/dropzone-uploader-editor.component';
import { UploadersModule } from '../uploaders/uploaders.module';
import { ModalEditorWrapperComponent } from './components/modal-editor-wrapper/modal-editor-wrapper.component';
import { TextareaEditorComponent } from './components/editors/textarea-editor/textarea-editor.component';
import { LoadingModule } from '../loading/loading.module';
import { ImageLibraryEditorComponent } from './components/editors/image-library-editor/image-library-editor.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ImagesModule } from '../../images/images.module';
import { ColorEditorComponent } from './components/editors/color-editor/color-editor.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
    declarations: [
        WizardEditorComponent,
        HtmlEditorComponent,
        CustomEditorComponent,
        InputEditorComponent,
        CodeEditorComponent,
        SelectEditorComponent,
        PlaceholderPipe,
        MaxValidatorDirective,
        MinValidatorDirective,
        EditPanelComponent,
        TextHtmlEditorComponent,
        ToggleEditorComponent,
        ListEditorComponent,
        DaterangeEditorComponent,
        RadioEditorComponent,
        CheckboxEditorComponent,
        TextareaEditorComponent,
        DropzoneUploaderEditorComponent,
        ModalEditorWrapperComponent,
        ImageLibraryEditorComponent,
        ColorEditorComponent
    ],
    imports: [
        CommonModule,
        LoadingModule,
        MatIconModule,
        NgSelectModule,
        MatSelectModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatDialogModule,
        ImagesModule,
        DateRangePickerModule,
        QuillModule.forRoot(),
        MonacoEditorModule,
        MatIconModule,
        FormsModule,
        UploadersModule,
        ColorChromeModule,
        ClickOutsideModule

    ],
    entryComponents: [],
    exports: [
        WizardEditorComponent,
        HtmlEditorComponent,
        CustomEditorComponent,
        InputEditorComponent,
        CodeEditorComponent,
        SelectEditorComponent,
        NgSelectModule,
        MatSlideToggleModule,
        PlaceholderPipe,
        EditPanelComponent,
        TextHtmlEditorComponent,
        ToggleEditorComponent,
        ListEditorComponent,
        DaterangeEditorComponent,
        RadioEditorComponent,
        CheckboxEditorComponent,
        DropzoneUploaderEditorComponent,
        TextareaEditorComponent,
        ImageLibraryEditorComponent,
        ModalEditorWrapperComponent,
        ColorEditorComponent
    ],
    providers: [
        EditPanelsService
    ]
})
export class EditorsModule {
}



