<ngx-dropzone class="ft2-dropzone"
              [class.-transparent]="transparent"
              (change)="onSelect($event)"
              [class.-multiple]="multiple"
              [multiple]="multiple"
              [accept]="accept">

    <ng-container *ngIf="loading">
        Uploading...
        <span class="ft2-spinner"></span>
    </ng-container>
    <ng-container *ngIf="!loading">
        <ngx-dropzone-label *ngIf="!uploadedFileUrl && !files.length">
            <mat-icon svgIcon="files"></mat-icon>
            <p>
                {{label || defaultLabel}}
            </p>
            <p>
                {{accept !== '*' ? '(' + accept + ')' : ''}}
            </p>
        </ngx-dropzone-label>
        <ng-container *ngIf="!imagePreview">
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>
                    <mat-icon svgIcon="file"></mat-icon>
                    {{ f.name }}
                </ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ng-container>
        <ng-container *ngIf="imagePreview">
            <ng-container *ngIf="files?.length; else uploadedPreview">
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                </ngx-dropzone-image-preview>
            </ng-container>
            <ng-template #uploadedPreview>
                <div class="ft2-dropzone_image-preview">
                    <img *ngIf="uploadedFileUrl" [src]="uploadedFileUrl">
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</ngx-dropzone>
