import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { val } from '@uirouter/core';

@Component({
    selector: 'd3-scatterplot',
    templateUrl: './scatterplot.component.html',
    styleUrls: ['./scatterplot.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScatterplotComponent implements OnChanges {
    @Input() xAxisLabel: string;
    @Input() yAxisLabel: string;
    @Input() lowerRankLabel: string;
    @Input() higherRankLabel: string;
    @Input() data: any[] = [];
    @Input() selectedData: any[] = [];
    @Input() xProperty = 'x';
    @Input() yProperty = 'y';
    @Input() width = 1200;
    @Input() height = 600;
    @Input() ticks = 3;
    margin = { top: 10, right: 10, bottom: 10, left: 10 };
    svg: any;
    xAxis: any;
    xValue: any;
    xScale: any;
    xMap: any;
    yAxis: any;
    yValue: any;
    yScale: any;
    yMap: any;
    tooltip: any;


    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.width || changes.height) {
            this.width = this.width - this.margin.left - this.margin.right;
            this.height = this.height - this.margin.top - this.margin.bottom;
        }

        if (changes.data) {
            if (this.data?.length > 0) {
                this.init();
            }
        }
    }

    init() {
        this.clear();
        this.createGraph();
        this.setupAxes();
        // this.createTooltip();
        this.createGradients();
        this.createGrid();
        this.createAxes();

        this.createAxisLabels();
        this.createRankLabels();
        this.createDots();
    }

    clear() {
        if (this.svg) {
            this.svg.selectAll('*').remove();
        }
    }

    setupAxes() {
        /*
         * value accessor - returns the value to encode for a given data object.
         * scale - maps value to a visual display encoding, such as a pixel position.
         * map function - maps from data value to display value
         * axis - sets up axis
         */

        // setup x
        this.xValue = (d) => d[this.xProperty];
        this.xScale = d3.scaleLinear().range([0, this.width]); // value -> display
        this.xMap = (d) => this.xScale(this.xValue(d));
        this.xAxis = d3.axisBottom(this.xScale);

        // setup y
        this.yValue = (d) => d[this.yProperty]; // data -> value
        this.yScale = d3.scaleLinear().range([this.height, 0]); // value -> display
        this.yMap = (d) => this.yScale(this.yValue(d));
        this.yAxis = d3.axisLeft(this.yScale);

        // don't want dots overlapping axis, so add in buffer to data domain
        const maxX = +d3.max(this.data, this.xValue);
        const minX = +d3.min(this.data, this.xValue);
        const gapX = maxX * 0.05;
        this.xScale.domain([minX - gapX, maxX + gapX]);

        const maxY = +d3.max(this.data, this.yValue);
        const minY = +d3.min(this.data, this.yValue);
        const gapY = maxY * 0.05 * this.width / this.height;
        this.yScale.domain([minY - gapY, maxY + gapY]);

    }

    createGraph() {
        // add the graph canvas to the body of the webpage
        this.svg = d3.select('#d3svg')
        // .attr('width', this.width + this.margin.left + this.margin.right)
        // .attr('height', this.height + this.margin.top + this.margin.bottom)
          .attr('viewBox', `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height + this.margin.top + this.margin.bottom}`)
          .append('g')
          .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    }

    createAxes() {
        this.svg.append('g')
          .call(this.xAxis.tickSize(0))
          .attr('class', 'd3-axis x')
          .attr('transform', 'translate(0,' + this.height + ')');


        this.svg.append('g')
          .call(this.yAxis.tickSize(0))
          .attr('class', 'd3-axis y');
    }

    createTooltip() {
        // add the tooltip area to the webpage
        this.tooltip = d3.select('body').append('div')
          .attr('class', 'd3-tooltip')
          .style('opacity', 0);
    }

    createDots() {

        // create dots
        this.svg.selectAll('circle')
          .data(this.data)
          .enter().append('circle')
          .attr('class', 'd3-dot')
          .attr('cx', this.xMap)
          .attr('cy', this.yMap);
        // .on('mouseover', (d) => {
        //     this.tooltip.transition()
        //       .duration(200)
        //       .style('opacity', .9);
        //     this.tooltip.html(d['name'] + '<br/> (' + this.xValue(d)
        //       + ', ' + this.yValue(d) + ')')
        //       .style('left', (d3.event.pageX + 5) + 'px')
        //       .style('top', (d3.event.pageY - 28) + 'px');
        // })
        // .on('mouseout', () => {
        //     this.tooltip.transition()
        //       .duration(500)
        //       .style('opacity', 0);
        // });

        if (this.selectedData.every(d => !!d)) {
            this.svg.append('circle')
              .data(this.selectedData)
              .attr('class', 'd3-active-dot')
              .attr('cx', this.xMap)
              .attr('cy', this.yMap);

            this.svg.append('circle')
              .data(this.selectedData)
              .attr('r', 15)
              .attr('stroke', '#486FD9')
              .attr('fill', 'transparent')
              .attr('cx', this.xMap)
              .attr('cy', this.yMap);
        }
    }

    createRankLabels() {
        this.svg.append('text')
          .attr('class', 'd3-label')
          .attr('text-anchor', 'end')
          .attr('x', this.width - 20)
          .attr('y', 30)
          .attr('fill', '#66AB75')
          .attr('opacity', 0.5)
          .text(this.higherRankLabel);


        this.svg.append('text')
          .attr('class', 'd3-label')
          .attr('x', 20)
          .attr('y', this.height - 30)
          .attr('fill', '#D0021B')
          .attr('opacity', 0.3)
          .text(this.lowerRankLabel);
    }

    createAxisLabels() {


        const xAxisLabel = this.svg.append('text')
          .attr('class', 'x d3-axis-label')
          .attr('text-anchor', 'end')
          .attr('x', this.width - 30)
          .attr('y', this.height + 5)
          .text(this.xAxisLabel);

        this.svg.append('rect')
          .attr('width', xAxisLabel.node().getComputedTextLength() + 30)
          .attr('height', 30)
          .attr('x', this.width - xAxisLabel.node().getComputedTextLength() - 45)
          .attr('y', this.height - 15)
          .style('fill', 'white');

        xAxisLabel.raise();


        const yAxisLabel = this.svg.append('text')
          .attr('class', 'y d3-axis-label')
          .attr('text-anchor', 'end')
          .attr('x', -30)
          .attr('y', 6)
          .attr('transform', 'rotate(-90)')
          .text(this.yAxisLabel);

        this.svg.append('rect')
          .attr('width', 30)
          .attr('height', yAxisLabel.node().getComputedTextLength() + 30)
          .attr('x', -15)
          .attr('y', 15)
          .style('fill', 'white');

        yAxisLabel.raise();
    }

    generateTicksValues(scale): number[] {
        const scaleMax = scale.domain()[1];
        const scaleMin = scale.domain()[0];
        const scaleDiff = scaleMax - scaleMin;
        const values = [];

        for (let i = 1; i <= this.ticks; i++) {
            const factor = 1 / (this.ticks + 1) * i;
            values.push(factor * scaleDiff + scaleMin);
        }

        return values;
    }


    createGrid() {

        const xAxisGrid = this.xAxis.tickSize(-this.height)
          .tickFormat('')
          .ticks(this.ticks)
          .tickValues(this.generateTicksValues(this.xScale));


        const yAxisGrid = this.yAxis.tickSize(-this.width)
          .tickFormat('')
          .ticks(this.ticks)
          .tickValues(this.generateTicksValues(this.yScale));


        this.svg.append('g')
          .attr('class', 'x d3-axis-grid')
          .attr('transform', 'translate(0,' + this.height + ')')
          .call(xAxisGrid);

        this.svg.append('g')
          .attr('class', 'y d3-axis-grid')
          .call(yAxisGrid);
    }

    createGradients() {

        const gridXGradient = this.svg.append('linearGradient')
          .attr('id', 'grid-x-gradient')
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '0%')
          .attr('y2', '-100%');


        gridXGradient.append('stop')
          .attr('offset', '0%')
          .attr('stop-color', '#dadada');

        gridXGradient.append('stop')
          .attr('offset', '100%')
          .attr('stop-color', '#bbea9d');


        const gridYGradient = this.svg.append('linearGradient')
          .attr('id', 'grid-y-gradient')
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '100%')
          .attr('y2', '100%');


        gridYGradient.append('stop')
          .attr('offset', '0%')
          .attr('stop-color', '#dadada');

        gridYGradient.append('stop')
          .attr('offset', '100%')
          .attr('stop-color', '#bbea9d');


        const axisXGradient = this.svg.append('linearGradient')
          .attr('id', 'axis-x-gradient')
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '100%')
          .attr('y2', '100%');


        axisXGradient.append('stop')
          .attr('offset', '15%')
          .attr('stop-color', '#cb2027');


        axisXGradient.append('stop')
          .attr('offset', '50%')
          .attr('stop-color', '#000');


        axisXGradient.append('stop')
          .attr('offset', '85%')
          .attr('stop-color', '#66AB75');


        const axisYGradient = this.svg.append('linearGradient')
          .attr('id', 'axis-y-gradient')
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '0%')
          .attr('y2', '100%');


        axisYGradient.append('stop')
          .attr('offset', '15%')
          .attr('stop-color', '#66AB75');


        axisYGradient.append('stop')
          .attr('offset', '50%')
          .attr('stop-color', '#000');


        axisYGradient.append('stop')
          .attr('offset', '85%')
          .attr('stop-color', '#cb2027');
    }

}
