import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ft-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnChanges {
    @Input() max = 100;
    @Input() value = 0;
    @Input() color = '#7ed321';
    @Input() startDate;
    @Input() endDate;
    public percentageValue = 0;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value || changes.max) {
            this.percentageValue = this.value * 100 / this.max;
        }
    }
}
