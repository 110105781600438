import {
    Component,
    Input,
    ContentChild,
    TemplateRef,
    HostListener,
} from "@angular/core";
import { DownloadService } from "../../services/download.service";

@Component({
    selector: "ft-file-download",
    templateUrl: "./file-download.component.html",
    styleUrls: ["./file-download.component.scss"],
})
export class FileDownloadComponent {
    @Input() name;
    @Input() url;
    @ContentChild(TemplateRef, { static: false })
    customTemplate: TemplateRef<any>;

    constructor(private download: DownloadService) { }

    @HostListener("click")
    downloadFile() {
        this.download.downloadFile(this.url);
    }
}
