<p class="text-muted" *ngIf="!src">{{placeholder}}</p>
<div class="ft2-image-preview"
     [style.width]="width"
     [style.height]="height"
     [class.-frame]="frame"
     [class.-dark]="darkBg"
     *ngIf="src">
    <img *ngIf="mode === 'img'"
         [src]="src">
    <div class="ft2-image-preview_bg"
         *ngIf="mode === 'background'"
         [ftBackgroundImage]="src">
    </div>
    <div class="ft2-image-preview_overlay" *ngIf="removable">
        <mat-icon (click)="remove.next()" class="icon-md">close</mat-icon>
    </div>
</div>
