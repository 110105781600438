<div class="ft2-checklist-container">
    <p class="ft2-checklist-title" *ngIf="title">
        {{title}}
    </p>
    <p class="mb-3" *ngIf="summary">
        {{summary}}
    </p>
    <ul class="ft2-checklist">
        <li *ngFor="let rule of rules">
            <span class="ft2-checklist_status" [class.-valid]="rule.isValid">
            </span>
            {{rule.text}}
        </li>
    </ul>
</div>
