import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment, ENVIRONMENT_TOKEN } from '../../core/environment.service';
import { UploadedImageResponse } from '../models/uploaded-image-response';

@Injectable({
    providedIn: 'root'
})
export class ImageUploaderService {
    constructor(
      private http: HttpClient,
      @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    static fileToFormData(file: File) {
        const data = new FormData();
        data.append('file', file, file.name);
        return data;
    }

    uploadCompanyImageAsset(companyUuid: string, file: File, type: number, size: string): Observable<UploadedImageResponse> {
        size = size || 'thumbnail';
        return this.http.post<UploadedImageResponse>(`${this.environment.apiV1BaseUrl}api/${companyUuid}/accounts/image?type=${type}&requiredSizes=${size}`, ImageUploaderService.fileToFormData(file));
    }

    uploadPropertyImageAsset(propertyUuid: string, companyUuid: string, file: File, type: number, size: string): Observable<UploadedImageResponse> {
        size = size || 'thumbnail';
        return this.http.post<UploadedImageResponse>(`${this.environment.apiV1BaseUrl}api/${companyUuid}/properties/${propertyUuid}/image?type=${type}&requiredSizes=${size}`, ImageUploaderService.fileToFormData(file));
    }

    uploadProfilePicture(file: File): Observable<{ profileImageUrl: string }> {
        return this.http.post<{ profileImageUrl: string }>(`${this.environment.apiBaseUrl}/profile-pictures`, ImageUploaderService.fileToFormData(file));
    }
}
