import {
    Directive,
    Input,
    ElementRef,
    Renderer2,
    Component,
    Optional,
    Host,
    OnDestroy,
    OnInit,
    OnChanges, SimpleChanges
} from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IntersectionObservableComponent } from '../dom/intersection-observer/intersection-observable.component';

@Component({
    selector: '[ftBackgroundImage]',
    template: '<ng-content></ng-content>',
    inputs: ['fallbackClass', 'fallbackImage', 'ftBackgroundImage', 'imageWidth', 'imageHeight', 'focusPoint']
})
export class BackgroundImageComponent implements OnInit, OnDestroy, OnChanges {

    fallbackClass: string | null;

    fallbackImage: string | null;

    hasCheckedImageExistance = false;
    hasImageUrl = false;
    imageHasChanged = false;
    isElementNeedsToBeVisible = false;
    isIntersecting = false;
    imageUrl;
    onIntersectingSub: Subscription;
    @Input() imageWidth: number;
    @Input() imageHeight: number;
    @Input() focusPoint: [number, number];
    @Input() ratio: [number, number];


    constructor(private elem: ElementRef,
                private renderer: Renderer2,
                @Optional() private intersectionObservable: IntersectionObservableComponent
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.imageWidth && changes.imageHeight && changes.focusPoint) {
            const left = this.focusPoint[0] / this.imageWidth * 100;
            const top = this.focusPoint[1] / this.imageHeight * 100;

            this.renderer.setStyle(this.elem.nativeElement, 'background-size', 'cover');
            this.renderer.setStyle(this.elem.nativeElement, 'background-position', `${left}% ${top}%`);
        }

        if (changes.ratio) {
            this.setHeight();
        }
    }

    setHeight() {
        const width = this.elem.nativeElement.offsetWidth;
        const height = width * this.ratio[1] / this.ratio[0];
        this.renderer.setStyle(this.elem.nativeElement, 'height', height + 'px');
    }

    ngOnInit(): void {
        this.isElementNeedsToBeVisible = !!this.intersectionObservable;
        if (!!this.intersectionObservable) {
            this.onIntersectingSub = this.intersectionObservable.onIsIntersecting.pipe(
                tap(isIntersecting => {
                    this.isIntersecting = isIntersecting;
                    this.setBackgroundImage();
                })).subscribe();
        }

        this.renderer.listen('window', 'resize', () => {
            if (this.ratio) {
                this.setHeight();
            }
        });
    }

    ngOnDestroy(): void {
        if (!!this.onIntersectingSub) {
            this.onIntersectingSub.unsubscribe();
        }
    }

    setFallbackClass() {
        if (!!this.fallbackClass) {
            this.fallbackClass.split(' ').filter(klass => !!klass).forEach(klass => {
                this.renderer.addClass(this.elem.nativeElement, klass);
            });
        }
    }

    setBackgroundImage() {
        if (!this.isElementNeedsToBeVisible || (this.isElementNeedsToBeVisible && this.isIntersecting)) {
            if (this.hasCheckedImageExistance && !this.imageHasChanged) {
                return;
            }
            this.hasCheckedImageExistance = true;
            this.checkImageExistance(this.imageUrl)
                .then((exists) => {
                    if (exists) {
                        this.renderer.setStyle(this.elem.nativeElement, 'background-image', `url(${this.imageUrl})`);
                    } else if (this.fallbackImage) {
                        this.renderer.setStyle(this.elem.nativeElement, 'background-image', `url(${this.fallbackImage})`);
                    } else {
                        this.renderer.setStyle(this.elem.nativeElement, 'background-image', `url()`);
                        this.setFallbackClass();
                    }
                });
        }
    }

    set ftBackgroundImage(val: string) {
        // todo: remove hack as ngjs was feeding empty strings
        const newImageUrl = val || this.elem.nativeElement.getAttribute('ft-background-image');
        this.hasImageUrl = !!newImageUrl;
        this.imageHasChanged = newImageUrl != this.imageUrl;
        this.imageUrl = newImageUrl;

        if (this.hasImageUrl || this.imageHasChanged) {
            this.setBackgroundImage();
        } else {
            this.setFallbackClass();
        }
    }


    checkImageExistance(url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.src = url;
            image.onload = function() {
                resolve(true);
                image = null;
            };
            image.onerror = function() {
                resolve(false);
                image = null;
            };
        });
    }

}
