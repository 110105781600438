import {
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { styleguideReducer } from "./styleguide.reducer";
import { SnapshotDataExample } from "../models/snapshot-data.model";

export interface StyleguideState {
    loading: boolean;
    data: SnapshotDataExample;
    error: any;
    text: { greetings: any[] };
    textLoading: boolean;
    textError: any;
}

export interface State {
    styleguide: StyleguideState;
}

export const reducers: ActionReducerMap<State> = {
    styleguide: styleguideReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
