import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    changeData,
    changeText,
    loadData,
    loadDataFailure,
    loadDataSuccess,
    loadText, loadTextFailure,
    loadTextSuccess
} from "./styleguide.actions";
import { delay, map } from "rxjs/operators";
import { SnapshotDataExample } from "../models/snapshot-data.model";
import * as _ from 'lodash';

const data: SnapshotDataExample = {
    firstName: 'John',
    lastName: 'Doe',
    address: '',
    role: 1,
    positionTitle: 'Mr.'
};

let text = [
    {
        id: 1,
        language: 'English',
        greeting: '<p><strong>Hello</strong></p>'
    },
    {
        id: 2,
        language: 'Russian',
        greeting: 'Привет'
    }
];

@Injectable()
export class StyleguideEffects {

    loadSnapshotData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadData),
            delay(1900),
            map(() => {
                return loadDataSuccess({data: _.cloneDeep(data)});
            })
        )
    );

    loadSnapshotText$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadText),
            delay(2000),
            map(() => {
                return loadTextSuccess({text: {greetings: _.cloneDeep(text)}});
            })
        )
    );

    changeTextData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeText),
            delay(500),
            map(({changes}) => {
                try {
                    if (changes.greeting) {
                        text = _.unionBy(changes.greeting, text, 'id');
                    }
                } catch (e) {
                    return loadTextFailure({error: 'Error!'});
                }
                return loadTextSuccess({text: {greetings: _.cloneDeep(text)}});
            })
        )
    );

    changeSnapshotData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeData),
            delay(1000),
            map(({changes}) => {
                try {
                    if (changes.name) {
                        if (changes.name.first) {
                            data.firstName = changes.name.first;
                        }
                        if (changes.name.last) {
                            data.lastName = changes.name.last;
                        }
                    }

                    if (changes.address) {
                        data.address = changes.address;
                    }
                    if (changes.teamStatus) {
                        if (changes.teamStatus.role) {
                            data.role = changes.teamStatus.role;
                        }
                        if (changes.teamStatus.positionTitle) {
                            data.positionTitle = changes.teamStatus.positionTitle;
                        }
                    }
                } catch (e) {
                    return loadDataFailure({error: 'Error!'});
                }
                return loadDataSuccess({data: _.cloneDeep(data)});
            })
        )
    );

    constructor(private actions$: Actions) {
    }

}
