import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { D3MapUnit } from '../../enums/d3-map-unit.enum';
import { D3MapDistanceScale } from '../../models/d3-map-unit-scale';
import { D3MapPoint } from '../../models/d3-map-point';
import * as _ from 'lodash';

'@angular/core';
import * as d3 from 'd3';
import * as topo from 'topojson';

@Component({
    selector: 'd3-zoom-map',
    templateUrl: './zoom-map.component.html',
    styleUrls: ['./zoom-map.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ZoomMapComponent implements OnInit, OnChanges {
    svg;
    projection;
    @Input() width = 600;
    @Input() height = 600;
    @Input() mapJsonUrl: string;
    @Input() units: D3MapUnit = D3MapUnit.mile;
    @Input() radius = 20;
    loading = true;
    scale: number;
    @Input() selectedCountry: string;
    @Input() countriesMaps: any[];
    @Input() destinations: any[];

    path;
    g;
    mainMap;
    activeFeature;
    features;

    constructor(private hostElement: ElementRef) {
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedCountry) {
            if (this.features) {
                this.zoomToCountry(this.selectedCountry);
            }
        }
    }

    async init() {
        this.createSvg();
        await this.drawMap(this.mapJsonUrl, 'countries');
        this.createCities();
    }

    createSvg() {
        this.svg = d3.select(this.hostElement.nativeElement.children[0]).append('svg');
        this.svg.attr('viewBox', `0 0 ${this.width} ${this.height}`);
        this.g = this.svg.append('g');
        this.g.attr('id', 'world-map-container');
    }

    drawMap(jsonUrl, prop): Promise<any> {
        this.scale = 200;

        // D3 Projection
        this.projection = d3.geoNaturalEarth1()
            .translate([this.width / 2, this.height / 2])
            .center([0, 0])
            .scale(this.scale);
        // Define path generator
        this.path = d3.geoPath()
            .projection(this.projection);


        return d3.json(jsonUrl).then((topology: any) => {
            this.loading = false;

            const geoJson = topo.feature(topology, topology.objects[prop]);
            this.features = geoJson.features;
            this.mainMap = this.g.append('g');

            this.mainMap.selectAll('path')
                .data(this.features)
                .enter().append('path')
                .attr('id', (d) => {
                    return d.properties.name;
                })
                .attr('d', this.path)
                .on('click', (d) => {
                    this.zoomToCountry(d.properties.name);
                })
                .attr('class', 'd3-map-state');

            return Promise.resolve(true);
        }).catch((err) => {
            this.loading = false;
            return Promise.reject(err);
        });
    }

    resetZoom() {
        this.activeFeature = null;
        this.g.transition()
            .duration(500)
            .attr('transform', '')
            .attr('opacity', 1);


        const zoomedCountry = this.svg.selectAll('.zoomed-country');
        zoomedCountry
            .transition()
            .attr('opacity', 0)
            .on('end', () => {
                zoomedCountry.attr('display', 'none');
            });
    }

    getZoomedCountryId(countryName: any) {
        return countryName.toLowerCase().replaceAll(' ', '_') + '_zoomed';
    }

    showCountry(feature) {
        this.projection = d3.geoMercator()
            .translate([this.width / 2, this.height / 2])
            .center([0, 0])
            .scale(this.scale);
        // Define path generator
        this.path = d3.geoPath()
            .projection(this.projection);

        const countryId = this.getZoomedCountryId(feature.properties.name);
        let zoomedCountrySvgGroup = this.svg.select('#' + countryId);


        if (!zoomedCountrySvgGroup._groups[0][0]) {
            zoomedCountrySvgGroup = this.svg.append('g');
            zoomedCountrySvgGroup.selectAll('path')
                .data([feature])
                .enter()
                .append('path')
                .attr('d', this.path)
                .on('click', (feature) => {
                    this.applyZoomTransition(feature);
                })
                .attr('class', 'd3-map-state');

            zoomedCountrySvgGroup.attr('id', this.getZoomedCountryId(feature.properties.name));
            zoomedCountrySvgGroup.attr('class', 'zoomed-country');

            const countryDestinations = this.destinations.filter(d => d.country === feature.properties.name);
            if (countryDestinations.length) {
                this.createPoint(countryDestinations, zoomedCountrySvgGroup);
            }
        }


        var b = this.path.bounds(feature);
        zoomedCountrySvgGroup
            .attr('display', 'block')
            .attr('opacity', 0);

        zoomedCountrySvgGroup.attr('transform',
            'translate(' + this.projection.translate() + ')'
            + 'scale(' + .8 / Math.max((b[1][0] - b[0][0]) / this.width, (b[1][1] - b[0][1]) / this.height) + ')'
            + 'translate(' + -(b[1][0] + b[0][0]) / 2 + ',' + -(b[1][1] + b[0][1]) / 2 + ')');

        zoomedCountrySvgGroup.transition()
            .duration(500)
            .attr('opacity', 1);

    }

    zoomToCountry(countryId: string) {
        const feature = this.features.find(f => {
            return f.properties.name === countryId;
        });
        this.applyZoomTransition(feature);
        setTimeout(() => {
            this.showCountry(feature);
        }, 200);
    }

    applyZoomTransition(feature) {
        if (feature === this.activeFeature) {
            this.resetZoom();
        } else {
            var b = this.path.bounds(feature);
            this.activeFeature = feature;
            this.g.attr('opacity', 1);
            this.g.transition().duration(750).attr('transform',
                'translate(' + this.projection.translate() + ')'
                + 'scale(' + .85 / Math.max((b[1][0] - b[0][0]) / this.width, (b[1][1] - b[0][1]) / this.height) + ')'
                + 'translate(' + -(b[1][0] + b[0][0]) / 2 + ',' + -(b[1][1] + b[0][1]) / 2 + ')')
                .attr('opacity', 0);
        }
    }

    createCities() {
        const groupedDestinations = _.groupBy(this.destinations, 'country');
        
        for (let country in groupedDestinations) {
            const destinations: any[] = groupedDestinations[country];
            if (destinations.length > 1) {
                const countryMap = this.countriesMaps.find((countryMap) => countryMap.name === country);
                this.createPlusPoint([countryMap]);
            } else {
                this.createPoint(destinations, this.g);
            }
        }

        this.g.selectAll('circle');
    }

    createPlusPoint(data) {
        this.g.selectAll('circle')
            .data(data)
            .enter()
            .append('a')
            .append('circle')
            .attr('class', 'd3-map-point')
            .attr('cx', (d) => this.projection([d.lon, d.lat])[0])
            .attr('cy', (d) => this.projection([d.lon, d.lat])[1])
            .attr('r', (d) => d.size ? d.size : 5)
            .attr('id', (d) => d.id);
    }

    createPoint(data, zoomedCountrySvgGroup) {
        zoomedCountrySvgGroup.selectAll('circle')
            .data(data)
            .enter()
            .append('a')
            .append('circle')
            .attr('class', 'd3-map-point')
            .attr('cx', (d) => this.projection([d.lon, d.lat])[0])
            .attr('cy', (d) => this.projection([d.lon, d.lat])[1])
            .attr('r', (d) => d.size ? d.size : 5)
            .attr('id', (d) => d.id);
    }
}
