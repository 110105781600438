import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-bem',
  templateUrl: './bem.component.html',
  styleUrls: ['./bem.component.scss']
})
export class BemComponent implements OnInit {

  example = `<div class="ft2-product"> // block
  <p class="ft2-product_name">Product name</p> // element
  <span class="ft2-product_price">$ 30.00</span> // element
  <span class="ft2-product_price -sale">$ 25.00</span> // element with modifier
  <div class="ft2-product_size ft2-product-size"> // block and element in same time
      <button class="ft2-product-size_btn">S</button> // element of nested block
      <button class="ft2-product-size_btn">M</button> // element of nested block
      <button class="ft2-product-size_btn">L</button> // element of nested block
  </div>
</div>`;

  constructor() { }

  ngOnInit(): void {
  }

}
