import { Input, Component } from '@angular/core';
import { ChecklistRule } from "./models/checklist-rule";

@Component({
    selector: 'ft-validation-checklist',
    templateUrl: './validation-checklist.component.html',
    styleUrls: ['./validation-checklist.component.scss']
})
export class ValidationChecklistComponent {
    @Input() rules: ChecklistRule[] = [];
    @Input() title: string;
    @Input() summary: string;
}
