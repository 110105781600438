import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class InputsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
