import {
  Component,
  forwardRef,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange } from '../../../../date-range-picker/models/date-range.model';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

@Component({
  selector: 'ft-daterange-editor',
  templateUrl: './daterange-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DaterangeEditorComponent)
    },
    {
      provide: MODAL_EDITOR_TOKEN,
      useExisting: DaterangeEditorComponent
    }
  ]
})
export class DaterangeEditorComponent extends ModalEditorBase {
  @Input() placeholder = 'Please select date range';
  @Input() pastDays = null;
  @Input() rangeLimit: number;
  @Input() futureDisabled = false;
  @Input() pastDisabled = false;


  constructor() {
    super();
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.value = dateRange;
  }
}


