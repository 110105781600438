import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';


@Component({
    selector: 'ft-toggle-editor',
    templateUrl: './toggle-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ToggleEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ToggleEditorComponent
        }
    ]
})
export class ToggleEditorComponent extends ModalEditorBase {
    constructor() {
        super();
    }
}

