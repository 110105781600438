import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SeasonsService } from "../../services/seasons.service";

@Component({
    selector: 'ft-season',
    template: `{{season}}`,
})
export class SeasonComponent implements OnChanges {
    season: string;
    @Input() increment = 0;

    constructor(private seasonsService: SeasonsService) {
        this.season = this.seasonsService.getSeason();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.increment) {
            this.season = this.seasonsService.getSeason(this.increment);
        }
    }
}
