import {
    Component,
    ContentChild, EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'ft-tree-node',
    templateUrl: './tree-node.component.html',
    styleUrls: ['./tree-node.component.scss'],
    animations: [
        trigger('slideDown', [
            state('*', style({
                height: '*'
            })),
            state('void', style({
                height: '0'
            })),
            transition('void <=> *', animate(200))
        ])
    ]
})
export class TreeNodeComponent implements OnInit {
    @Input() isCollapsed = true;
    @ContentChild('node') nodeTemplate: TemplateRef<any>;
    @ContentChild('children') childrenTemplate: TemplateRef<any>;
    @Output() remove = new EventEmitter();
    @Input() removable = false;

    constructor() {
    }

    collapse() {
        this.isCollapsed = true;
    }

    expand() {
        this.isCollapsed = false;
    }

    ngOnInit(): void {
    }

}
