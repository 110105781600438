import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StyleguideRoutingModule } from './styleguide-routing.module';
import { StyleguideComponent } from './styleguide.component';
import { StyleguideMenuComponent } from './components/styleguide-menu/styleguide-menu.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { ThemingComponent } from './components/theming/theming.component';
import { JourneysComponent } from './components/journeys/journeys.component';
import { TypographyComponent } from './components/typography/typography.component';
import { BemComponent } from './components/bem/bem.component';
import { HIGHLIGHT_OPTIONS, HighlightModule, HighlightOptions } from 'ngx-highlightjs';
import { InputsComponent } from './components/inputs/inputs.component';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { SelectsComponent } from './components/selects/selects.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { PanelsComponent } from './components/panels/panels.component';
import { SharedModule } from 'shared';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditorsComponent } from './components/editors/editors.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { StyleguideIconsService } from './services/styleguide-icons.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnapshotComponent } from './components/snapshot/snapshot.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StyleguideEffects } from './store/styleguide.effects';
import { D3Module } from '@flipto/d3';
import { D3Component } from './components/d3/d3.component';
import { TreePageComponent } from './components/tree-page/tree-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImagesPageComponent } from './components/images-page/images-page.component';

@NgModule({
  declarations: [
    StyleguideComponent,
    StyleguideMenuComponent,
    ButtonsComponent,
    ThemingComponent,
    JourneysComponent,
    TypographyComponent,
    BemComponent,
    InputsComponent,
    SelectsComponent,
    HomeComponent,
    PanelsComponent,
    EditorsComponent,
    DatepickerComponent,
    SnapshotComponent,
    D3Component,
    TreePageComponent,
    ImagesPageComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HighlightModule,
    HttpClientModule,
    NgSelectModule,
    MatIconModule,
    MatSlideToggleModule,
    StyleguideRoutingModule,
    SharedModule,
    D3Module,
    DragDropModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([StyleguideEffects])
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        lineNumbers: true
      }
    },
    StyleguideIconsService
  ],
  bootstrap: [StyleguideComponent]
})
export class StyleguideModule {
  constructor(private iconsService: StyleguideIconsService) {
    this.iconsService.register();
  }
}


