<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ft-dropzone-uploader
            [transparent]="true"
            [uploadedFileUrl]="value"
            [multiple]="multiple"
            [accept]="accept"
            [imagePreview]="imagePreview"
            (selected)="onSelect($event)">
    </ft-dropzone-uploader>
</ft-modal-editor-wrapper>
