import {
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    InjectionToken, Directive, OnInit
} from '@angular/core';
import { ControlValueAccessor, NgModel } from '@angular/forms';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

export const MODAL_EDITOR_TOKEN = new InjectionToken('MODAL_EDITOR_TOKEN');

@Directive()
export abstract class ModalEditorBase implements ControlValueAccessor {

    @Input() set value(value: any) {
        this.value$.next(_.cloneDeep(value));
    }

    get value() {
        return this.value$.getValue();
    }

    @ViewChild('control', { read: NgModel, static: false }) control: NgModel;
    @ContentChild('title', { static: false }) titleTemplate: TemplateRef<any>;
    @ContentChild('summary', { static: false }) summaryTemplate: TemplateRef<any>;
    @Input() title: string;
    @Input() summary: string;
    @Input() buttonLabel = 'Save';
    @Input() name: string;
    @Input() required: boolean;
    @Input() placeholder = '';
    @Input() label: string;
    @Input() saveSuccess$: Observable<any>;
    @Input() error$: Observable<any>;
    @Input() defaultError = 'Error processing request';
    value$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    initialValue: any;
    onChangeFn: any;
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();
    isNgModelExist = false;
    visible = false;


    constructor() {
    }

    writeValue(value: any): void {
        this.value = value;
        if (!this.initialValue) {
            this.initialValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
        this.isNgModelExist = true;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    visibilityChanged(state: boolean) {
        this.visible = state;
        if (this.visible) {
            this.onEditorVisible();
        } else {
            this.onEditorHidden();
        }
    }

    onEditorVisible(): void {
    }

    onEditorHidden(): void {
    }

    onModelChange(value: any) {
        if (this.isNgModelExist) {
            this.value = value;
        }
    }

    onModelInit(): Observable<any> {
        return this.value$.pipe(
          filter((value => !!value)),
          first()
        );
    }

    get outputValue() {
        if (this.name) {
            return { [this.name]: this.value };
        }

        return this.value;
    }

    isInvalid() {
        if (this.control) {
            return this.control.invalid;
        }
        return false;
    }

    updateInitialValue() {
        this.initialValue = this.value;
    }

    applyChanges() {
        if (this.isNgModelExist) {
            this.onChangeFn(this.value);
        }
        this.save.next(this.outputValue);
    }

    onCancelChanges() {
        this.value = this.initialValue;
        if (this.isNgModelExist) {
            this.onChangeFn(this.initialValue);
        }
    }
}
