import { OnInit, Injector, Input, TemplateRef, Directive, ViewContainerRef } from '@angular/core';
import { AreasRegistry, AREAS_REGISTRY } from './areas.service';
import { UIRouter } from '@uirouter/core';
import { AreasRegistryContext } from './areas-registry-context';


@Directive({
    selector: '[ftAreas]',
    // inputs: ["registryName: ftAreasRegistryName"]
})
export class AreasDirective implements OnInit {

    static DEFAULT_AREAS_REGISTRY = AREAS_REGISTRY;

    @Input()
    ftAreasRegistryName: string | undefined;


    $injector: any;
    context: AreasRegistryContext;

    constructor(private injector: Injector,
                private router: UIRouter,
                private templateRef: TemplateRef<AreasRegistryContext>,
                private viewContainerRef: ViewContainerRef) {
        this.$injector = this.injector.get("$injector");
    }

    ngOnInit() {
        this.setupRegistry(this.ftAreasRegistryName || AreasDirective.DEFAULT_AREAS_REGISTRY);
    }

    setupRegistry(registryService: string) {
        const registry = this.injector.get(registryService) as AreasRegistry;
        if (!!registry) {
            this.context = new AreasRegistryContext(registry, this.$injector, this.router);
            this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
        }
    }

}
