import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { saveAs } from "file-saver";

@Injectable({ providedIn: "root" })
export class DownloadService {
    constructor(private http: HttpClient) { }

    downloadFile(url: string) {
        this.http.get(url, { responseType: "blob", observe: "response" }).subscribe(res => saveAs(res.body, this.getFileNameFromHeaders(res)));
    }

    getFileNameFromHeaders(response) {
        return (response instanceof HttpResponse ? response.headers.get('content-disposition') : response.headers('Content-Disposition'))
            .match(/filename="?([^";]+)/)[1]
    }

    download(url) {
        return new Promise((resolve, reject) => {
            this.http.get(url, { responseType: "blob", observe: "response" }).subscribe(response => {
                resolve({
                    content: response.body,
                    filename: this.getFileNameFromHeaders(response)
                })
            }, error => reject(error));
        });
    }
}
