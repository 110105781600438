<h1 class="display-2">
    Inputs
</h1>
<section class="sg-section">
    <h2 class="h3">
        States
    </h2>
    <div class="row">
        <div class="col-4">
            <input class="ft2-input" type="text" placeholder="Default">
        </div>
        <div class="col-4">
            <input class="ft2-input" type="text" placeholder="Disabled" disabled>
        </div>
        <div class="col-4">
            <input class="ft2-input -dark" type="text" placeholder="Dark">
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Shapes
    </h2>
    <div class="row">
        <div class="col-4">
            <input class="ft2-input -round" type="text" placeholder="Round">
        </div>
        <div class="col-4">
            <input class="ft2-input -radius" type="text" placeholder="Radius">
        </div>
        <div class="col-4">
            <input class="ft2-input -square" type="text" placeholder="Square">
        </div>
    </div>
</section>

<section class="sg-section">
    <h2 class="h3">
        Sizes
    </h2>
    <div class="row">
        <div class="col-4">
            <input class="ft2-input -large" type="text" placeholder="Large">
        </div>
        <div class="col-4">
            <input class="ft2-input -medium" type="text" placeholder="Medium">
        </div>
        <div class="col-4">
            <input class="ft2-input -small" type="text" placeholder="Small">
        </div>
    </div>
</section>

<section class="sg-section">
    <h2 class="h3">
        Input group
    </h2>
    <div class="row">
        <div class="col-6">
            <div class="ft2-input-group">
                <div class="ft2-input-group_prepend">
                    <button class="ft2-btn-primary">Prepend</button>
                </div>
                <input class="ft2-input" type="text">
            </div>
        </div>
        <div class="col-6">
            <div class="ft2-input-group">
                <input class="ft2-input" type="text">
                <div class="ft2-input-group_append">
                    <button class="ft2-btn-primary">Append</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Input with icon
    </h2>
    <div class="row">
        <div class="col-6">
            <div class="ft2-input-wrapper">
                <mat-icon class="ft2-input-icon">email</mat-icon>
                <input class="ft2-input" type="text">
            </div>
        </div>
        <div class="col-6">
            <div class="ft2-input-wrapper">
                <input class="ft2-input" type="text">
                <mat-icon class="ft2-input-icon">email</mat-icon>
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        In Form
    </h2>
    <div class="row">
        <div class="col-4">
            <div class="ft2-form-group">
                <label>Email</label>
                <input class="ft2-input" type="text" placeholder="Enter your email">
            </div>
        </div>
        <div class="col-4">
            <div class="ft2-form-group">
                <label>Email</label>
                <input class="ft2-input -valid" type="text" placeholder="Enter your email" value="user@email.com">
            </div>
        </div>
        <div class="col-4">
            <div class="ft2-form-group">
                <label>Email</label>
                <input class="ft2-input -invalid" type="text" placeholder="Enter your email" value="useremail.com">
                <div class="ft2-form-error">Please enter valid email</div>
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Textarea
    </h2>
    <div class="row">
        <div class="col-12">
            <textarea class="ft2-textarea" rows="5">Textarea</textarea>
        </div>
    </div>
</section>
