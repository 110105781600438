<h1 class="display-3">
    Welcome to Flip.to style guide!
</h1>
<section class="sg-section">
    <p>
        Please navigate with sidebar menu through the style guide sections.<br>
        If you have any questions please contact
        <a class="ft2-link -underline text-secondary" href="mailto:dsinitsyn@flip.to">dsinisyn@flip.to</a>.
    </p>
</section>
