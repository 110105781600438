<div class="ft2-upload-area"
     [style.border-width]="borderWidth + 'px'"
     [style.border-style]="borderStyle"
     [style.width]="width + 'px'"
     [style.height]="height + 'px'">
    <span *ngIf="!isUploading" class="ft2-upload-area_plus" [style.font-size]="height / 3 + 'px'">
        +
    </span>
    <span *ngIf="isUploading" class="ft2-spinner"></span>
</div>
