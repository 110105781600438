import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';

@Component({
  selector: 'ft-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() buttonText = 'Upload file';
  @Input() url: string;
  @Input() multiple = false;
  @Input() accept = '*';
  @Output() selected = new EventEmitter<any>();
  @Output() failure = new EventEmitter<any>();
  uploader: FileUploader;

  constructor() {
  }


  ngOnInit() {
    this.uploader = new FileUploader({
      url: this.url,
      autoUpload: true
    });

    this.uploader.onErrorItem = (item, response, status) => this.onErrorItem(item, response, status);
  }

  onErrorItem(item: FileItem, response: string, status: number): any {
    this.failure.next({ message: response, status });
  }

  onSelect() {
    this.failure.next();
  }
}

