import { Directive, Input, HostListener } from '@angular/core';
import { ModalService } from './modal.service';

@Directive({
    selector: '[ft-targets-modal],[ftTargetsModal]'
})
export class TargetsModalDirective {

    @Input("ft-targets-modal")
    id: string;

    @Input("ftTargetsModal")
    set ftTargetsModal(id: string) {
        this.id = id;
    }

    constructor(private modalService: ModalService) {
    }

    @HostListener("click")
    showModal() {
        this.modalService.show(this.id);
    }
}
