import {
    Component,
    ViewChild,
    forwardRef,
    Input,
    ElementRef,
    OnChanges
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorToken } from '../../../models/editor-token';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { InputFieldType } from '../../../types/input-field.type';


@Component({
    selector: 'ft-textarea-editor',
    templateUrl: './textarea-editor.component.html',
    styleUrls: ['./textarea-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextareaEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: TextareaEditorComponent
        }
    ]
})
export class TextareaEditorComponent extends ModalEditorBase implements OnChanges {
    @ViewChild('textareaElement', { static: false }) textareaElement: ElementRef;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() pattern: string;
    @Input() patternValidationText: string;
    @Input() type: InputFieldType = 'text';
    @Input() tokens: EditorToken[] = [];
    selectedToken: EditorToken = null;


    constructor() {
        super();
    }

    ngOnChanges() {
        if (this.type === 'url') {
            this.patternValidationText = 'Value is not valid url.';
            this.pattern = 'https?://.+';
        }
    }

    onTokenSelected(token) {
        const selectionStart = this.textareaElement.nativeElement.selectionStart;
        this.value = this.value ? this.value.slice(0, selectionStart) + `${token}` + this.value.slice(selectionStart) : token;

        setTimeout(() => {
            this.selectedToken = null;
            this.textareaElement.nativeElement.focus();
        });
    }
}


