import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChecklistRule } from 'shared';
import { Observable } from 'rxjs';

@Component({
    selector: 'ft-checklist-save',
    templateUrl: './checklist-save.component.html',
    styleUrls: ['./checklist-save.component.scss']
})
export class ChecklistSaveComponent implements OnInit {
    @Input() rules: ChecklistRule[] = [];
    @Input() title: string;
    @Input() summary: string;
    @Input() loading$: Observable<boolean> | Array<Observable<boolean>> | boolean;
    @Output() save = new EventEmitter();


    constructor() {
    }

    ngOnInit(): void {
    }

    isChecklistValid() {
        return this.rules.every(rule => rule.isValid);
    }

}
