import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-styleguide-menu',
  templateUrl: 'styleguide-menu.component.html',
  styleUrls: ['./styleguide-menu.component.scss']
})
export class StyleguideMenuComponent implements OnInit {

  menu = [
    {
      name: 'BEM',
      url: 'bem'
    },
    {
      name: 'Theming',
      url: 'theming'
    },
    {
      name: 'Typography',
      url: 'typography'
    },
    {
        name: 'Journeys',
        url: 'journeys'
    },
    {
      name: 'Elements',
      url: 'elements',
      submenu: [
        {
          name: 'Buttons',
          url: 'buttons'
        },
        {
          name: 'Inputs',
          url: 'inputs'
        },
        {
          name: 'Selects',
          url: 'selects'
        },
        {
          name: 'Datepicker',
          url: 'datepicker'
        },
        {
          name: 'Panels',
          url: 'panels'
        },
        {
          name: 'Editors',
          url: 'editors'
        },
        {
          name: 'Tree',
          url: 'tree'
        },
        {
          name: 'Images',
          url: 'images'
        }
      ]
    },
    {
      name: 'Snapshot',
      url: '/snapshot'
    },
    {
      name: 'D3',
      url: '/d3'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
