import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialLettersPipe } from './initial-letters.pipe';
import { JoinWithPipe } from './join-with.pipe';
import { FullNamePipe } from './full-name.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { CssUrlPipe } from './css-url.pipe';
import { ClonePipe } from './clone.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeStylePipe } from './safe-style.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        InitialLettersPipe,
        JoinWithPipe,
        FullNamePipe,
        SafeUrlPipe,
        CssUrlPipe,
        ClonePipe,
        OrdinalPipe,
        SafeHtmlPipe,
        SafeStylePipe,
    ],
    exports: [
        InitialLettersPipe,
        JoinWithPipe,
        FullNamePipe,
        SafeUrlPipe,
        CssUrlPipe,
        ClonePipe,
        OrdinalPipe,
        SafeHtmlPipe,
        SafeStylePipe
    ]
})
export class PipesModule {

}
