<h1 class="display-2">
    Images
</h1>
<section class="sg-section">
    <h2 class="h3">
        ftBackgroundImage directive
    </h2>
    <h3 class="h5">
        Options
    </h3>
    <table class="sg-table">
        <tr>
            <td>
                @Input <code>imageWidth: number</code>
            </td>
            <td>
                original width of image in px
            </td>
        </tr>
        <tr>
            <td>
                @Input <code>imageHeight: number</code>
            </td>
            <td>
                original width of image in px
            </td>
        </tr>
        <tr>
            <td>
                @Input <code>focusPoint: [number, number]</code>
            </td>
            <td>
                coordinates of focus point in px
            </td>
        </tr>
        <tr>
            <td>
                @Input <code>ratio: [number, number]</code>
            </td>
            <td>
                frame ratio depending of width
            </td>
        </tr>
    </table>
    <h3 class="h5">
        Examples
    </h3>
    <div [style.width]="'50%'"
         [style.height]="'300px'"
         ftBackgroundImage="https://specials-images.forbesimg.com/imageserve/1153850565/960x0.jpg?fit=scale"
         [imageWidth]="960"
         [imageHeight]="640"
         [focusPoint]="[785, 210]">
    </div>
    <br>
    <br>
    <div [style.width]="'50%'"
         ftBackgroundImage="https://specials-images.forbesimg.com/imageserve/1153850565/960x0.jpg?fit=scale"
         [imageWidth]="960"
         [imageHeight]="640"
         [ratio]="[2, 1]"
         [focusPoint]="[785, 210]">
    </div>
</section>

