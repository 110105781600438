<h1 class="display-2">
    Datepicker
</h1>
<section class="sg-section">
    <h2 class="h3">
        Date range picker
    </h2>
    <ft-daterange-picker [horizontalAppearance]="'right'"></ft-daterange-picker>
    <h3 class="h5 mt-5">
        Base options
    </h3>
    <p>@Input <code>storageKey: string</code> - key to store daterange value in browser localStorage, to save value per
        page reload
    </p>
    <p>@Input <code>from: string | Date</code> - preselected start date</p>
    <p>@Input <code>to: string | Date</code> - preselected end date</p>
    <p>@Input <code>pastDays: number</code> - preselect daterange based on amount of past days</p>
    <p>@Input <code>rangeLimit: number</code> - limit daterange in days</p>
    <p>@Input <code>darkMode: boolean</code> - enable dark mode <code>(default: false)</code></p>
    <p>@Input <code>inlineMode: boolean</code> - show daterange as plain text <code>(default: false)</code></p>
    <p>@Input <code>durationVisible: boolean</code> - show daterange duration in days <code>(default: true)</code></p>
    <p>@Input <code>datesVisible: boolean</code> - show dates <code>(default: true)</code></p>
    <p>@Input <code>futureDisabled: boolean</code> - disallow to select date from future <code>(default: false)</code>
    </p>
    <p>@Input <code>pastDisabled: boolean</code> - disallow to select date from past <code>(default: false)</code></p>
    <p>@Input <code>hidden: boolean</code> - don't show daterange <code>(default: false)</code></p>
    <p>@Input <code>verticalAppearance: 'bottom' | 'top'</code> - vertical position of daterange popup <code>(default:
        'bottom')</code></p>
    <p>@Input <code>horizontalAppearance: 'left' | 'center' | 'right'</code> - horizontal position of daterange popup
        <code>(default: 'center')</code></p>
    <br>
    <p>@ContentChild <code>placeholderTmpl: TemplateRef</code> - custom template for placeholder</p>
    <p>@ContentChild <code>valueTmpl: TemplateRef</code> - custom template for value presentation</p>
    <br>
    <p>@Output <code>changed: EventEmitter(DateRange)</code> - triggers when daterange has changed</p>
    <h3 class="h5">
        Examples
    </h3>
    <div>
        <ft-daterange-picker [futureDisabled]="true" [rangeLimit]="60"></ft-daterange-picker>
    </div>
    <div>
        <ft-daterange-picker [darkMode]="true" [pastDays]="10" [verticalAppearance]="'top'" [horizontalAppearance]="'left'"></ft-daterange-picker>
    </div>
    <div class="mt-4">
        <ft-daterange-picker [inlineMode]="true" [durationVisible]="false" [datesVisible]="true" [horizontalAppearance]="'left'" [from]="'01-01-2020'" [to]="'01-07-2020'"></ft-daterange-picker>
    </div>
</section>
