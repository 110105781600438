import * as moment_ from 'moment'; // fix from https://github.com/jvandemo/generator-angular2-library/issues/221
const moment = moment_;

export class DateRange {
    toDate: Date;
    fromDate: Date;
    duration: number;

    constructor(fromDate: string | Date, toDate: string | Date) {
        if (toDate) {
            this.toDate = moment(toDate).toDate();
        }
        if (fromDate) {
            this.fromDate = moment(fromDate).toDate();
        }
        if (this.fromDate && this.toDate) {
            this.duration = this.calcDuration();
        }
    }

    static forPast90Days(): DateRange {
        return new DateRange(moment().subtract(90, 'day').toDate(), moment().subtract(1, 'day').toDate());
    }

    static forPast60Days(): DateRange {
        return new DateRange(moment().subtract(60, 'day').toDate(), moment().subtract(1, 'day').toDate());
    }

    static forPast30Days(): DateRange {
        return new DateRange(moment().subtract(30, 'day').toDate(), moment().subtract(1, 'day').toDate());
    }

    static forPast7Days(): DateRange {
        return new DateRange(moment().subtract(7, 'day').toDate(), moment().subtract(1, 'day').toDate());
    }

    static forNext9Weeks(): DateRange {
        return new DateRange(moment().startOf('isoWeek').toDate(), moment().startOf('isoWeek').add(10, 'weeks').add(-1, 'days').toDate());
    }

    static forNext8Weeks(): DateRange {
        return new DateRange(moment().startOf('isoWeek').toDate(), moment().startOf('isoWeek').add(9, 'weeks').add(-1, 'days').toDate());
    }

    calcDuration(): number {
        return moment(this.toDate).diff(this.fromDate, 'days') + 1;
    }

    toParams() {
        return {
            StartDate: moment(this.fromDate).format('YYYY-MM-DD'),
            EndDate: moment(this.toDate).format('YYYY-MM-DD')
        };
    }
}

