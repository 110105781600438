import { Component, OnInit } from '@angular/core';
import { D3MapPoint } from '../../../../../../libs/d3/src/lib/models/d3-map-point';
import { D3MapDistanceScale } from '../../../../../../libs/d3/src/lib/models/d3-map-unit-scale';
import * as _ from 'lodash';

@Component({
    selector: 'sg-d3',
    templateUrl: './d3.component.html'
})
export class D3Component {
    source = [
        {
            'City': 'Fayetteville',
            'Lat': 35.0527,
            'Lon': -78.8784,
            'Users': 791,
            'Transactions': 15,
            'Revenue': 4245.0,
            'Distance': 138418.86327540857
        },
        {
            'City': 'Cary',
            'Lat': 35.7915,
            'Lon': -78.7811,
            'Users': 275,
            'Transactions': 11,
            'Revenue': 1939.0,
            'Distance': 219460.90668768698
        },
        {
            'City': 'Columbia',
            'Lat': 34.0007,
            'Lon': -81.0348,
            'Users': 408,
            'Transactions': 9,
            'Revenue': 2410.0,
            'Distance': 219334.35449635857
        },
        {
            'City': 'North Myrtle Beach',
            'Lat': 33.816,
            'Lon': -78.68,
            'Users': 886,
            'Transactions': 8,
            'Revenue': 776.0,
            'Distance': 1199.841033636477
        },
        {
            'City': 'Sanford',
            'Lat': 35.4799,
            'Lon': -79.1803,
            'Users': 316,
            'Transactions': 8,
            'Revenue': 2076.0,
            'Distance': 190425.62763828938
        },
        {
            'City': 'Albemarle',
            'Lat': 35.3501,
            'Lon': -80.2001,
            'Users': 181,
            'Transactions': 7,
            'Revenue': 1925.0,
            'Distance': 220490.76339284814
        },
        {
            'City': 'Clayton',
            'Lat': 35.6507,
            'Lon': -78.4564,
            'Users': 300,
            'Transactions': 7,
            'Revenue': 1959.12,
            'Distance': 204477.09589276987
        },
        {
            'City': 'Apex',
            'Lat': 35.7327,
            'Lon': -78.8503,
            'Users': 165,
            'Transactions': 6,
            'Revenue': 1686.0,
            'Distance': 213330.37838382236
        },
        {
            'City': 'Conway',
            'Lat': 33.836,
            'Lon': -79.0478,
            'Users': 258,
            'Transactions': 6,
            'Revenue': 751.0,
            'Distance': 35131.93191012293
        }
    ];
    center = {
        name: 'Myrtle Beach',
        lat: 33.68,
        lon: -78.88
    };
    points: D3MapPoint[];
    distanceScales: D3MapDistanceScale[] = [
        {
            value: 50,
            label: '50'
        },
        {
            value: 100,
            label: '100'
        },
        {
            value: 150,
            label: '150'
        }
    ];

    selectedId: any;


    selectedCountry: string;

    // data = [
    //     {
    //         x: 45242,
    //         y: 1.6
    //     },
    //     {
    //         x: 23242,
    //         y: 1.2
    //     },
    //     {
    //         x: 33234,
    //         y: 4.1
    //     },
    //     {
    //         x: 12333,
    //         y: 2.5
    //     },
    //     {
    //         x: 5321,
    //         y: 1.8
    //     },
    //     {
    //         x: 36342,
    //         y: 3.4
    //     }
    // ];
    // data2 = [
    //     {
    //         name: 'Charleston',
    //         lat: 32.77,
    //         lon: -79.93,
    //         traffic: 196970,
    //         conversion: 1.58
    //     },
    //     {
    //         name: 'Raleigh-Durham',
    //         lat: 35.77,
    //         lon: -78.63,
    //         traffic: 85422,
    //         conversion: 1.48
    //     },
    //     {
    //         name: 'Columbia ',
    //         lat: 34.00,
    //         lon: -81.03,
    //         traffic: 102768,
    //         conversion: 1.13
    //     },
    //     {
    //         name: 'High Point',
    //         lat: 35.95,
    //         lon: -80.00,
    //         traffic: 67722,
    //         conversion: 1.67
    //     }
    //     // {
    //     //     name: 'LEFT ',
    //     //     lat: 33.68,
    //     //     lon: -75.45,
    //     //     traffic: 102768,
    //     //     conversion: 1.13
    //     // },
    //     // {
    //     //     name: 'RIGHT',
    //     //     lat: 33.68,
    //     //     lon: -82.31,
    //     //     traffic: 67722,
    //     //     conversion: 1.67
    //     // },
    //     // {
    //     //     name: 'TOP ',
    //     //     lat: 36.49,
    //     //     lon: -78.88,
    //     //     traffic: 102768,
    //     //     conversion: 1.13
    //     // },
    //     // {
    //     //     name: 'BOTTOM',
    //     //     lat: 30.78,
    //     //     lon: -78.88,
    //     //     traffic: 67722,
    //     //     conversion: 1.67
    //     // }
    // ];
    //
    // center = {
    //     name: 'Myrtle Beach',
    //     lat: 33.68,
    //     lon: -78.88
    // };
    //
    // points: D3MapPoint[] = this.data2.map((d) => {
    //     return {
    //         name: d.name,
    //         lat: d.lat,
    //         lon: d.lon,
    //         tooltip: `<p>Traffic: ${d.traffic}</p><p>Conversion: ${d.conversion}</p>`
    //     };
    // });
    //
    // distanceScales: D3MapDistanceScale[] = [
    //     {
    //         value: 50,
    //         label: '50'
    //     },
    //     {
    //         value: 100,
    //         label: '100'
    //     },
    //     {
    //         value: 150,
    //         label: '150'
    //     }
    // ];

    countriesMaps = [
        {
            name: 'United States of America',
            jsonUrl: 'assets/json/topo/usa-counties-10m.json',
            lat: 39.771089858349164,
            lon: -102.04184771848418
        },
        {
            name: 'Ukraine',
            jsonUrl: 'assets/json/topo/countries-110m.json',
            lat: 39.771089858349164,
            lon: -102.04184771848418
        }
    ];

    destinations = [
        {
            country: 'United States of America',
            state: 'New York',
            city: 'New York',
            name: 'Midtown',
            lat: 40.8156,
            lon: -73.9433
        },
        {
            country: 'United States of America',
            state: 'New York',
            city: 'New York',
            name: 'Meatpacking district',
            lat: 40.775962,
            lon: -73.910818
        },
        {
            country: 'United States of America',
            state: 'New York',
            city: 'New York',
            name: 'Long Island City',
            lat: 40.71569301609646,
            lon: -73.9962717779029

        },
        {
            country: 'United States of America',
            state: 'New York',
            city: 'New York',
            name: 'Dumbo',
            lat: 40.71941286836163,
            lon: -73.93564501948971
        },
        {
            country: 'United States of America',
            state: 'Sourth Carolina',
            city: 'Myrtle Beach',
            name: 'Myrtle Beach',
            lat: 33.68,
            lon: -78.88
        },
        {
            country: 'United States of America',
            state: 'Texas',
            city: 'Grapevine',
            name: 'Gaylord Texan',
            lat: 32.955367,
            lon: -97.063991
        },
        {
            country: 'United States of America',
            state: 'California',
            city: 'Santa Monica',
            name: 'Santa Monica Hotel',
            lat: 34.01823111605597,
            lon: -118.49199939386435
        }
        // {
        //     country: 'France',
        //     state: 'Paris',
        //     city: 'Paris',
        //     name: 'Paris Hotel',
        //     lat: 48.874695966979566,
        //     lon: 2.3738319092145157
        // }
    ];

    constructor() {
        this.createPoints();
    }

    createPoints() {
        const maxPoint = _.maxBy(this.source, 'Users').Users;
        const minPoint = _.minBy(this.source, 'Users').Users;
        const minSize = 5;
        const maxSize = 15;
        const pointsDiff = maxPoint - minPoint;
        const sizesDiff = maxSize - minSize;

        this.points = this.source.map((d) => {
            return {
                name: '',
                id: d.City,
                size: Math.round(minSize + (d.Users - minPoint) * sizesDiff / pointsDiff),
                lat: d.Lat,
                lon: d.Lon,
                tooltip: `<p><b>${d.City}</b></p><p>Traffic: ${d.Users}</p><p>Conversion: ${d.Transactions}</p>`
            };
        });
    }
}
