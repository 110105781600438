import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'ft-pending-changes',
    templateUrl: './pending-changes.component.html'
})
export class PendingChangesComponent implements OnInit {
    @Input() totalChanges: number;
    @Input() hasChanges: boolean;
    @Input() loading$: Observable<boolean> | Array<Observable<boolean>> | boolean;
    @Output() commit = new EventEmitter();
    @Output() cancel = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

}
