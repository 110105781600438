import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    SimpleChanges
} from '@angular/core';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { EditPanelsService } from '../../services/edit-panels.service';
import { SnapshotFormComponent } from '../../../snapshot/components/snapshot-form/snapshot-form.component';

@Component({
    selector: 'ft-edit-panel',
    templateUrl: './edit-panel.component.html',
    styleUrls: ['./edit-panel.component.scss']
})
export class EditPanelComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() label: string;
    @Input() summary: string;
    @Input() error: any;
    @Input() loading: boolean;
    @Input() disabled = false;
    @Input() static = false;
    @Input() readOnly = false;
    @Input() fluidLabel = false;
    isSnapshotChildren = false;
    isAlive = true;


    constructor(private editPanelsService: EditPanelsService,
                @Optional() private snapshot?: SnapshotFormComponent) {
    }

    @HostListener('click', ['$event'])
    markPanelAsTouched(event) {
        this.editPanelsService.markPanelAsTouched(this, this.isSnapshotChildren);
        if (this.readOnly || this.disabled) {
            event.stopPropagation();
        }
    }

    get isTouched(): boolean {
        return this.editPanelsService.isPanelTouched(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // prevent showing error and loading state if those was triggered by other panel
        if (!this.isTouched && (changes.loading || changes.error)) {
            this.loading = false;
            this.error = null;
        }
    }

    ngAfterViewInit(): void {
        if (!!this.snapshot) {
            this.isSnapshotChildren = true;
            if (!!this.snapshot.onCommitSuccess$) {
                merge(this.snapshot.onCommitSuccess$, this.snapshot.cancel).pipe(
                  filter((isReset) => !!isReset),
                  takeWhile(() => this.isAlive)
                ).subscribe(() => {
                    this.editPanelsService.reset();
                    this.error = null;
                });
            }
        }
    }

    ngOnDestroy() {
        this.isAlive = false;
        this.editPanelsService.reset();
    }
}
