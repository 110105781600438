import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaterangePickerComponent } from "./components/daterange-picker/daterange-picker.component";
import { NgbDatepickerModule, NgbTooltipConfig, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgbDatePipe } from "./pipes/ngb-date.pipe";
import { ClickOutsideModule } from "ng-click-outside";


@NgModule({
    declarations: [
        DaterangePickerComponent,
        NgbDatePipe
    ],
    entryComponents: [
        DaterangePickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ClickOutsideModule,
        NgbDatepickerModule,
        NgbTooltipModule
    ],
    exports: [
        DaterangePickerComponent
    ]
})
export class DateRangePickerModule {
    constructor() {
    }
}
