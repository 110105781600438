<h1 class="display-2">
    Panels
</h1>
<div class="ft2-container">
    <section class="sg-section">
        <h2 class="h3 mb-5">
            States
        </h2>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">Default</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel [label]="'Label'">
                    <p>Panel content</p>
                </ft-edit-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">Disabled</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel [label]="'Label'" [disabled]="true">
                    <p>Panel content</p>

                </ft-edit-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">Read-only</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel [label]="'Label'" [readOnly]="true">
                    <p>Panel content</p>
                </ft-edit-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">Static</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel [label]="'Label'" [static]="true">
                    <p class="mr-auto">Panel content</p>
                </ft-edit-panel>
            </div>
        </div>
    </section>

    <section class="sg-section">
        <h2 class="h3 mb-5">
            With controls
        </h2>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">With Button</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel [label]="'Label'" [static]="true">
                    <button type="button"
                            class="ft2-btn-dark -outline">
                        Button
                    </button>
                </ft-edit-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">With Toggle</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel [label]="'Label'" [fluidLabel]="true" [static]="true">
                    <mat-slide-toggle></mat-slide-toggle>
                </ft-edit-panel>
            </div>
        </div>
    </section>
    <section class="sg-section">
        <h2 class="h3 mb-5">
            Panel groups
        </h2>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">Panel group</p>
            </div>
            <div class="col-8 col-md-12">
                <div class="ft2-edit-panel-group">
                    <ft-edit-panel [label]="'Label'">
                        Panel content
                    </ft-edit-panel>
                    <ft-edit-panel [label]="'Label'">
                        Panel content
                    </ft-edit-panel>
                    <ft-edit-panel [label]="'Label'">
                        Panel content
                    </ft-edit-panel>
                </div>
            </div>
        </div>
    </section>
    <section class="sg-section">
        <h2 class="h3 mb-5">
            Custom layouts
        </h2>
        <div class="row">
            <div class="col-4 col-md-12 mb-3">
                <p class="solid-text medium-text">Examples</p>
            </div>
            <div class="col-8 col-md-12">
                <ft-edit-panel>
                    Panel content without label
                </ft-edit-panel>
                <ft-edit-panel [label]="'Fluid label can be wider than default label'" [fluidLabel]="true">
                    Panel content
                </ft-edit-panel>
                <ft-edit-panel [label]="'Label and summary'" [summary]="'Edit panel summary description'">
                    Panel content
                </ft-edit-panel>
                <ft-edit-panel [label]="'Grid columns'">
                    Panel content
                    <div class="col-12">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae facere
                            ipsum itaque molestiae natus.
                        </p>
                    </div>
                </ft-edit-panel>
            </div>
        </div>
    </section>
</div>

