<h1 class="display-2">
    Theming
</h1>
<section class="sg-section">
    <h2 class="h3">
        Colors
    </h2>
    <div class="row">
        <div class="col-12">
            <div class="sg-color bg-primary">
                <strong class="sg-color_name">
                    Primary
                </strong>
                <span class="sg-color_code">
                    #66AB75
                </span>
            </div>
            <div class="sg-color bg-primary-dark">
                <strong class="sg-color_name">
                    Primary dark
                </strong>
                <span class="sg-color_code">
                    #0f8275
                </span>
            </div>
            <div class="sg-color bg-secondary">
                <strong class="sg-color_name">
                    Secondary
                </strong>
                <span class="sg-color_code">
                    #4A90E2
                </span>
            </div>
            <div class="sg-color bg-secondary-dark">
                <strong class="sg-color_name">
                    Secondary dark
                </strong>
                <span class="sg-color_code">
                    #3669a7
                </span>
            </div>
            <div class="sg-color bg-success">
                <strong class="sg-color_name">
                    Success
                </strong>
                <span class="sg-color_code">
                    #2CC02A
                </span>
            </div>
            <div class="sg-color bg-warning">
                <strong class="sg-color_name">
                    Warning
                </strong>
                <span class="sg-color_code">
                    #ff8533
                </span>
            </div>
            <div class="sg-color bg-danger">
                <strong class="sg-color_name">
                    Danger
                </strong>
                <span class="sg-color_code">
                    #cb2027
                </span>
            </div>
            <div class="sg-color bg-light">
                <strong class="sg-color_name">
                    Light
                </strong>
                <span class="sg-color_code">
                    #f3f3f6
                </span>
            </div>
            <div class="sg-color bg-dark">
                <strong class="sg-color_name">
                    Dark
                </strong>
                <span class="sg-color_code">
                    #20292f
                </span>
            </div>
            <div class="sg-color bg-blackish">
                <strong class="sg-color_name">
                    Blackish
                </strong>
                <span class="sg-color_code">
                    #20292f
                </span>
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Backgrounds
    </h2>
    <div class="row">
        <div class="col-6">
            <div class="bg-primary p-3 mb-3">
                .bg-primary
            </div>
            <div class="bg-primary-dark p-3 mb-3">
                .bg-primary-dark
            </div>
            <div class="bg-secondary p-3 mb-3">
                .bg-secondary
            </div>
            <div class="bg-secondary-dark p-3 mb-3">
                .bg-secondary-dark
            </div>
            <div class="bg-success p-3 mb-3">
                .bg-success
            </div>
            <div class="bg-warning p-3 mb-3">
                .bg-warning
            </div>
            <div class="bg-danger p-3 mb-3">
                .bg-danger
            </div>
            <div class="bg-light p-3 mb-3">
                .bg-light
            </div>
            <div class="bg-dark p-3 mb-3">
                .bg-dark
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Gradient backgrounds
    </h2>
    <div class="row">
        <div class="col-6">
            <div class="bg-primary-gradient p-3 mb-3">
                .bg-primary-gradient
            </div>
            <div class="bg-secondary-gradient p-3 mb-3">
                .bg-secondary-gradient
            </div>
            <div class="bg-success-gradient p-3 mb-3">
                .bg-success-gradient
            </div>
            <div class="bg-warning-gradient p-3 mb-3">
                .bg-warning-gradient
            </div>
            <div class="bg-danger-gradient p-3 mb-3">
                .bg-danger-gradient
            </div>
            <div class="bg-light-gradient p-3 mb-3">
                .bg-light-gradient
            </div>
            <div class="bg-dark-gradient p-3 mb-3">
                .bg-dark-gradient
            </div>
            <div class="bg-blue-night-gradient p-3 mb-3">
                .bg-blue-night-gradient
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Colored text
    </h2>
    <div class="row">
        <div class="col-12">
            <div class="text-primary mb-2">
                .text-primary
            </div>
            <div class="text-muted mb-2">
                .text-muted
            </div>
            <div class="text-primary-dark mb-2">
                .text-primary-dark
            </div>
            <div class="text-secondary mb-2">
                .text-secondary
            </div>
            <div class="text-secondary-dark mb-2">
                .text-secondary-dark
            </div>
            <div class="text-success mb-2">
                .text-success
            </div>
            <div class="text-warning mb-2">
                .text-warning
            </div>
            <div class="text-danger mb-2">
                .text-danger
            </div>
            <div class="text-light mb-2">
                .text-light
            </div>
            <div class="text-dark mb-2">
                .text-dark
            </div>
        </div>
    </div>
</section>
