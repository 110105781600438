<h1 class="display-2">
    D3
</h1>
<div class="sg-container">
    <section class="sg-section">
        <h2 class="h3">
            Map
        </h2>
        <h3 class="h5">
            Options
        </h3>
        <table class="sg-table">
            <tr>
                <td>
                    @Input <code>width: number</code>
                </td>
                <td>
                    width of map
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>height: number</code>
                </td>
                <td>
                    height of map
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>mapJsonUrl: string</code>
                </td>
                <td>
                    url to TopoJSON file with map
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>scale: number</code>
                </td>
                <td>
                    scale (zoom) of map
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>radial: boolean</code>
                </td>
                <td>
                    whether map has circle shape or not
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>units: D3MapUnit</code>
                </td>
                <td>
                    units of distance on map in kilometers or miles ('km' / 'mi')
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>distanceScales: D3MapDistanceScale[]</code>
                </td>
                <td>
                    radial scale of distance from center point of map in format
                    <code>&#123; value: number, label: string &#125;;</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>points: D3MapPoint[]</code>
                </td>
                <td>
                    map points with longitude and latitude values
                    <code>&#123; name: string, lat: number, lon: number, tooltip: string &#125;</code>
                </td>
            </tr>
            <tr>
                <td>
                    @Input <code>centerPoint: D3MapPoint</code>
                </td>
                <td>
                    point at the center of the map
                </td>
            </tr>
        </table>
        <h3 class="h5">
            Examples
        </h3>
        <div class="row">
            <div class="col-8">
                <d3-map [mapJsonUrl]="'assets/json/topo/usa-counties-10m.json'"
                        [centerPoint]="center"
                        [points]="points"
                        [distanceScales]="distanceScales"
                        [radial]="true"></d3-map>
            </div>
            <div class="col-4">
                <d3-map [mapJsonUrl]="'assets/json/topo/usa-counties-10m.json'"
                        [centerPoint]="points[0]"
                        [width]="400"
                        [height]="300"></d3-map>
            </div>
        </div>
    </section>
    <section class="sg-section">
        <h2 class="h3">
            World Zoom Map
        </h2>
        <div>
            <d3-zoom-map [mapJsonUrl]="'assets/json/topo/countries-110m.json'"
                         [width]="1000"
                         [countriesMaps]="countriesMaps"
                         [destinations]="destinations"
                         [selectedCountry]="selectedCountry">
            </d3-zoom-map>

            <button class="ft2-btn-primary" (click)="selectedCountry = 'United States of America'">
                USA
            </button>
            <button class="ft2-btn-primary" (click)="selectedCountry = 'Japan'">
                Japan
            </button>
            <button class="ft2-btn-primary" (click)="selectedCountry = 'Ukraine'">
                Ukraine
            </button>
        </div>
    </section>

</div>


