import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_TOKEN = new InjectionToken("ENVIRONMENT_TOKEN");

export interface Environment {
    name: string;
    loginUrl: string;
    apiBaseUrl: string;
    apiV1BaseUrl: string;
    v1BaseUrl: string;
    v1HomePageUrl: string;
    googleChartsMapApiKey: string;
    aceBasePath: string;
    FliptoHubDomain: string;
    FliptoBaseHelpCenterUrl: string;
    FliptoPlatformDomain: string;
    AzureCdnBaseUrl: string;
    CurationShareContentSize: string;
    version: string;
}
