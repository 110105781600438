<div class="d3-map"
     [style.max-width]="width + 'px'"
     [style.max-height]="height + 'px'">
    <div *ngIf="loading" class="d3-map_loading v-center">
        <span class="ft2-spinner"></span>
        Loading map...
    </div>
    <button (click)="resetZoom()">reset</button>
</div>


