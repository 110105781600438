import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sg-buttons',
    templateUrl: './buttons.component.html'
})
export class ButtonsComponent implements OnInit {

    constructor() {
    }
    ngOnInit() {
    }
}
