<div class="styleguide">
    <div class="sg-sidebar">
        <a class="sg-sidebar_logo" routerLink="/">
            <img src="assets/img/logo.png" alt="">
            Styleguide
        </a>
        <sg-styleguide-menu>
        </sg-styleguide-menu>
    </div>
    <div class="sg-content">
        <router-outlet></router-outlet>
    </div>
</div>
