<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div [style.max-width]="'768px'">
        <ng-container *ngIf="isArrayValue(); else textValue">
            <ft-text-html-editor *ngFor="let item of value"
                                 [model]="item"
                                 [required]="required"
                                 [email]="email"
                                 [plain]="plain"
                                 [maxlength]="maxlength"
                                 [minlength]="minlength"
                                 [pattern]="pattern"
                                 [tokens]="tokens"
                                 [fields]="fields">
            </ft-text-html-editor>
        </ng-container>
        <ng-template #textValue>
            <ft-text-html-editor [(model)]="value"
                                 [required]="required"
                                 [email]="email"
                                 [maxlength]="maxlength"
                                 [plain]="plain"
                                 [minlength]="minlength"
                                 [pattern]="pattern"
                                 [tokens]="tokens"
                                 [fields]="fields">
            </ft-text-html-editor>
        </ng-template>
    </div>
</ft-modal-editor-wrapper>
