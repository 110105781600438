<h1 class="display-2">
    Snapshot
</h1>
<section class="sg-section">
    <div class="row">
        <div class="col-8">
            <h3 class="h5">
                Usage
            </h3>
            <p>When form's changes need to be send all in one request, with ability to cancel changes.</p>
            <p>Component to use - <code>ft-snapshot-form</code></p>
            <h3 class="h5">
                Options
            </h3>
            <p>@Input <code>commitSuccess$: Observable&#60;any&#62;</code>- Observable which triggers when data
                successfully saved after changes.
            </p>
            <p>@Input <code>commitLoading$: Observable&#60;boolean&#62;</code>- Observable of ngrx data saving loading.
            </p>
            <p>@Output <code>commit: EventEmitter</code> - triggers when user clicks on Save &amp; publish button,
                returns form changes.
            </p>
            <p>@Output <code>cancel: EventEmitter</code> - triggers when user clicks on Cancel button to return form
                initial state.
            </p>
            <h3 class="h5">
                Notes
            </h3>
            <p>
                Inside <code>ft-snapshot-form</code> you need to provide <code>[(ngModel)]</code> with <code>name</code>
                attribute to notify snapshot to watch for changes of these models. If you want to create nested form,
                you need to use <code>ngModelGroup</code>, but then don't forget to wrap everything in <code>form</code>
                tag with following attribute: <code>#form="ngForm"</code>.
            </p>
            <h2 class="h5">
                Example
            </h2>
        </div>
    </div>
    <ft-snapshot-form [loading$]="[loading$, textLoading$]"
                      [commitSuccess$]="[changeSuccess$, changeTextSuccess$]"
                      (commit)="onFormCommit($event)">
        <div class="row">
            <div class="col-8">
                <form ngForm>
                    <ng-container *ngIf="data$ | async | clone as data">
                        <div class="ft2-edit-panel-group" ngModelGroup="name">
                            <ft-input-editor title="First name"
                                             name="first"
                                             required
                                             [(ngModel)]="data.firstName">
                                <ft-edit-panel label="Name" [loading]="loading$ | async">
                                    <p>{{data.firstName}}</p>
                                </ft-edit-panel>
                            </ft-input-editor>
                            <ft-input-editor title=" Last name"
                                             name="last"
                                             [(ngModel)]="data.lastName">
                                <ft-edit-panel label="Last name" [loading]="loading$ | async">
                                    <p>{{data.lastName}}</p>
                                </ft-edit-panel>
                            </ft-input-editor>
                        </div>
                        <ft-custom-editor title="Address">
                            <div content>
                                <div class="ft2-form-group">
                                    <input class="ft2-input -large"
                                           type="text"
                                           maxlength="100"
                                           name="address"
                                           required
                                           [(ngModel)]="data.address">
                                </div>
                            </div>
                            <ft-edit-panel label="Address" [loading]="loading$ | async">
                                <p>{{data.address}}</p>
                            </ft-edit-panel>
                        </ft-custom-editor>
                        <ft-custom-editor title="Team status">
                            <div ngModelGroup="teamStatus" content>
                                <div class="ft2-form-group">
                                    <label>Role</label>
                                    <ng-select [items]="roles"
                                               class="ft2-select -large mb-3"
                                               name="role"
                                               [bindLabel]="'role'"
                                               [bindValue]="'id'"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [(ngModel)]="data.role">
                                    </ng-select>
                                </div>
                                <div class="ft2-form-group">
                                    <label>Position title</label>
                                    <input class="ft2-input -large"
                                           type="text"
                                           maxlength="100"
                                           name="positionTitle"
                                           required
                                           [(ngModel)]="data.positionTitle">
                                </div>
                            </div>
                            <ft-edit-panel label="Team status" [loading]="loading$ | async">
                                <div>
                                    <p *ngIf="data.role">Role id: {{data.role}}</p>
                                    <p *ngIf="data.positionTitle">Position
                                        title: {{data.positionTitle}}</p>
                                </div>
                            </ft-edit-panel>
                        </ft-custom-editor>
                    </ng-container>
                    <ng-container *ngIf="text$ | async | clone as text">
                        <ft-html-editor title="Html editor"
                                        [fields]="[{name: 'greeting', label: 'Provide greeting message'}]"
                                        [tokens]="[{name: 'Hotel name', value: '{hotel}'}, {name: 'Guest name', value: '{guest}'}]"
                                        name="greeting"
                                        [(ngModel)]="text.greetings">
                            <ft-edit-panel label="Text" [loading]="textLoading$ | async">
                                <div class="d-flex">
                                    <div *ngFor="let t of text.greetings" class="mr-5">
                                        {{t.language}}:
                                        <div [innerHTML]="t.greeting"></div>
                                    </div>
                                </div>
                            </ft-edit-panel>
                        </ft-html-editor>
                    </ng-container>
                </form>
            </div>
        </div>
    </ft-snapshot-form>
</section>

