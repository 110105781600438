import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: 'ft-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {
    @Input() loading$: Observable<boolean> | Observable<boolean>[];
    @Input() error$: Observable<any> | Observable<any>[];
    @Input() data$: Observable<any>;
    @Input() loadingText = 'Loading data...';
    @Input() errorText = 'Error processing request';
    @Input() error404Text = 'There is no data';
    @Input() inlineMode = false;
    loadingSubscription$: Observable<boolean>;
    errorSubscription$: Observable<any>;

    constructor() {
    }

    ngOnInit() {
        this.handleLoading();
        this.handleError();
    }

    handleError() {
        if (Array.isArray(this.error$)) {
            this.errorSubscription$ = combineLatest(this.error$).pipe(
                map((errors: any[]) => {
                    return errors.find(error => !!error);
                })
            );
        } else {
            this.errorSubscription$ = this.error$;
        }
    }

    handleLoading() {
        if (Array.isArray(this.loading$)) {
            this.loadingSubscription$ = combineLatest(this.loading$).pipe(
                map((isLoadings: boolean[]) => {
                    if (isLoadings.some((isLoading) => isLoading === true)) {
                        return true;
                    }
                    if (isLoadings.every((isLoading) => isLoading === false)) {
                        return false;
                    }
                })
            );
        } else {
            this.loadingSubscription$ = this.loading$;
        }
    }

    isArray(object: any): boolean {
        return Array.isArray(object);
    }
}

