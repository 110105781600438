import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class StyleguideIconsService {
    iconsFolderPath = 'assets/img/icons/';
    icons = [
        'info',
        'arrow-down',
        'arrow-up',
        'arrow-left',
        'arrow-right',
        'close',
        'code',
        'target',
        'lock',
        'dots',
        'search',
        'file',
        'files',
        'conversations',
        'visitors',
        'planning',
        'share',
        'ft-icn-file-ignore',
        'ft-icn-file-processed',
        'ft-icn-file-upload',
        'error',
        'cloud_download',
        'touchpoint',
        'check',
        'grade'
    ];

    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    }

    register() {
        this.icons.forEach(icon => {
            this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`${this.iconsFolderPath}${icon}.svg`));
        });
    }
}

