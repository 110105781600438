import {
  Component,
  forwardRef,
  Input,
  ViewChildren,
  QueryList
} from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';


@Component({
  selector: 'ft-radio-editor',
  templateUrl: './radio-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioEditorComponent)
    },
    {
      provide: MODAL_EDITOR_TOKEN,
      useExisting: RadioEditorComponent
    }
  ]
})
export class RadioEditorComponent extends ModalEditorBase {
  @Input() options: any[];
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() bindSummary: string;
  @ViewChildren('control', { read: NgModel }) controls: QueryList<NgModel>;


  constructor() {
    super();
  }

  onModelChange(value: any) {
    this.value = value;
  }

  isInvalid() {
    if (this.controls) {
      return this.controls.some(control => {
        return control.invalid;
      });
    }
    return super.isInvalid();
  }
}

