import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { LibraryImage } from '../../../models/library-image.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModalEditorWrapperComponent } from '../../modal-editor-wrapper/modal-editor-wrapper.component';
import { ImageLibraryService } from '../../../services/image-library.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ft-image-library-editor',
    templateUrl: './image-library-editor.component.html',
    styleUrls: ['./image-library-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ImageLibraryEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ImageLibraryEditorComponent
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class ImageLibraryEditorComponent extends ModalEditorBase implements OnChanges {
    @ViewChild(ModalEditorWrapperComponent) editorWrapper: ModalEditorWrapperComponent;
    @ViewChild('tabs', { static: false }) tabs;
    @Input() activeTabIndex = 1;
    @Input() getUrl: string;
    @Input() uploadUrl: string;
    @Input() uploadFromUrl: string;
    @Input() accept = '.jpg, .jpeg, .png, .gif';
    @Input() preview = false;
    @Input() outputAsset = false;
    @Input() removable = false;
    @Output() remove = new EventEmitter<LibraryImage>();
    externalImageUrl: string;
    loading$ = new Subject<boolean>();
    selectedImage: LibraryImage;
    images$: BehaviorSubject<LibraryImage[]>;

    constructor(private http: HttpClient, private libraryService: ImageLibraryService) {
        super();
        this.loading$ = this.libraryService.loading$;
    }

    onEditorVisible(): void {
        this.setInitialTab();
        this.tabs.realignInkBar();
    }

    setInitialTab() {
        if (this.preview) {
            this.activeTabIndex = 0;
        } else {
            this.activeTabIndex = 1;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.preview) {
            this.setInitialTab();
        }

        if (changes.getUrl && this.getUrl) {
            this.setImages();
        }
    }

    get outputValue() {
        if (this.name) {
            return { [this.name]: this.outputAsset ? this.selectedImage : this.value };
        }
        return this.outputAsset ? this.selectedImage : this.value;
    }

    public changeModelImage(image: LibraryImage) {
        this.selectedImage = image;
        this.writeValue(image.url);
        this.preview = true;
        setTimeout(() => {
            this.setInitialTab();
        }, 10);
    }

    setImages() {
        this.images$ = this.libraryService.loadAll(this.getUrl);
    }


    public getImageName(url: string) {
        if (this.images$) {
            return this.images$.getValue().find((img) => img.url === url)?.name;
        }
    }

    public async onImageFileUpload(files: File[]) {
        const image = await this.libraryService.uploadImageFromFile(files[0], this.uploadFromUrl);
        this.addImage(image);
        this.changeModelImage(image);
    }

    public async onImageFromUrlUpload(externalImageUrl: string) {
        const image = await this.libraryService.uploadImageFromUrl(externalImageUrl, this.uploadUrl);
        this.externalImageUrl = null;
        this.addImage(image);
        this.changeModelImage(image);
    }

    private addImage(image: LibraryImage) {
        const existed = this.images$.getValue();
        this.images$.next([...existed, image]);
    }


    public onRemove() {
        this.remove.next(this.selectedImage);
        this.value = null;
        this.editorWrapper.dialogRef.close();
    }
}
