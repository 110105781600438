import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[ftSticky]'
})
export class StickyDirective implements AfterViewInit {
    @Input() stickyOffset = 0;
    @Input() stickyBoundary: HTMLElement;
    elementPosition: any;
    elementHeight: any;
    isStickyTop = false;
    isStickyBottom = false;

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngAfterViewInit() {
        this.initElement();

        this.renderer.listen('window', 'scroll', (e) => {
            this.watchPosition();
        });
        this.renderer.listen('window', 'resize', () => {
            this.unstickTop();
            if (this.stickyBoundary) {
                this.unstickBottom();
            }
            this.initElement();
        });

    }

    initElement() {
        this.elementPosition = this.el.nativeElement.getBoundingClientRect().top + window.pageYOffset;
        this.elementHeight = this.el.nativeElement.offsetHeight;
        this.watchPosition();
    }

    watchPosition() {
        const windowScroll = window.pageYOffset;
        if (windowScroll > 0) {
            if (windowScroll >= this.elementPosition - this.stickyOffset) {
                if (!this.isStickyTop) {
                    this.stickTop();
                }
            } else {
                if (this.isStickyTop) {
                    this.unstickTop();
                }
            }
        }

        if (this.stickyBoundary) {
            if (windowScroll + this.elementHeight + this.stickyOffset > this.stickyBoundary.offsetTop + this.stickyBoundary.offsetHeight) {
                if (!this.isStickyBottom) {
                    this.stickBottom();
                }
            } else {
                if (this.isStickyBottom) {
                    this.unstickBottom();
                    this.stickTop();
                }
            }
        }
    }


    stickBottom() {
        this.renderer.removeStyle(this.el.nativeElement, 'top');
        this.renderer.setStyle(this.stickyBoundary, 'position', 'relative');
        this.renderer.setStyle(this.el.nativeElement, 'position', 'absolute');
        this.renderer.setStyle(this.el.nativeElement, 'bottom', '0');
        this.isStickyBottom = true;
    }

    unstickBottom() {
        this.renderer.removeStyle(this.stickyBoundary, 'position');
        this.renderer.removeStyle(this.el.nativeElement, 'position');
        this.renderer.removeStyle(this.el.nativeElement, 'bottom');
        this.isStickyBottom = false;
    }


    stickTop() {
        const elementWidth = this.el.nativeElement.offsetWidth + 'px';
        this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
        this.renderer.setStyle(this.el.nativeElement, 'top', '0');
        this.renderer.setStyle(this.el.nativeElement, 'width', elementWidth);
        this.isStickyTop = true;
    }

    unstickTop() {
        this.renderer.removeStyle(this.el.nativeElement, 'position');
        this.renderer.removeStyle(this.el.nativeElement, 'top');
        this.renderer.removeStyle(this.el.nativeElement, 'width');
        this.isStickyTop = false;
    }
}
