import { Action, createReducer, on } from "@ngrx/store";
import { State, StyleguideState } from "./index";
import {
    changeData,
    changeText,
    loadData,
    loadDataFailure,
    loadDataSuccess,
    loadText, loadTextFailure,
    loadTextSuccess
} from "./styleguide.actions";

export const initialState: StyleguideState = {
    data: undefined,
    error: undefined,
    loading: false,
    text: undefined,
    textError: undefined,
    textLoading: false
};

const reducer = createReducer(
    initialState,
    on(loadData, state => {
        return {
            ...state,
            loading: true
        };
    }),
    on(changeData, state => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadDataSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            data
        };
    }),
    on(loadDataFailure, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(loadText, state => {
        return {
            ...state,
            textLoading: true
        };
    }),
    on(changeText, state => {
        return {
            ...state,
            textLoading: true
        };
    }),
    on(loadTextSuccess, (state, {text}) => {
        return {
            ...state,
            textLoading: false,
            text
        };
    }),
    on(loadTextFailure, (state, {error}) => {
        return {
            ...state,
            textLoading: false,
            textError: error
        };
    })
);

export function styleguideReducer(state: StyleguideState | undefined, action: Action) {
    return reducer(state, action);
}

