import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChildren,
    QueryList, OnInit
} from '@angular/core';
import Quill from 'quill';
import { NgModel } from '@angular/forms';
import { EditorField } from '../../../models/editor-field';
import { EditorToken } from '../../../models/editor-token';


@Component({
    selector: 'ft-text-html-editor',
    templateUrl: 'text-html-editor.component.html',
    styleUrls: ['./text-html-editor.component.scss']
})
export class TextHtmlEditorComponent {
    @ViewChildren('input', { read: NgModel }) inputs: QueryList<NgModel>;
    @Input() plain: boolean;
    @Input() fields: EditorField[];
    @Input() tokens: EditorToken[];
    @Input() required: boolean;
    @Input() email: boolean;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() pattern: string;
    @Input() placeholder = '';
    @Input() model: any;
    @Output() modelChange = new EventEmitter<any>();

    isSourceCodeVisible = false;
    selectedToken = null;
    activeEditorIndex: number | null = null;
    quillEditors: Quill[] = [];
    monacoEditors: any[] = [];
    quillConfig = {
        toolbar: {
            toolbar: '#toolbar',
            container: [
                ['bold']
            ]
        }
    };
    monacoOptions = {
        formatOnPaste: true,
        formatOnType: true,
        autoIndent: true,
        language: 'html'
    };

    constructor() {
    }

    onModelChanged(model: string, field?: EditorField) {
        if (field) {
            this.model[field.name] = model;
        } else {
            this.model = model;
        }

        this.modelChange.next(this.model);
    }

    onBlur(i) {
        if (i === this.activeEditorIndex && this.fields) {
            this.activeEditorIndex = null;
        }
    }

    onFocus(index: number) {
        this.activeEditorIndex = index;
    }

    onQuillEditorCreated(event, index) {
        this.quillEditors[index] = event;

        if (index === 0) {
            this.focusEditor(event);
        }
    }

    focusEditor(editor) {
        setTimeout(() => {
            editor.focus();
        }, 200);
    }

    monacoEditorCreated(event, index) {
        this.monacoEditors[index] = event;
    }

    changeFormat() {
        this.isSourceCodeVisible = !this.isSourceCodeVisible;
    }

    onTokenSelected(token: string) {
        if (this.isSourceCodeVisible) {
            this.insertTokenInMonaco(token);
        } else {
            this.insertTokenInQuill(token);
        }

        setTimeout(() => {
            this.selectedToken = null;
        });
    }


    insertTokenInQuill(token: string) {
        if (token) {
            const selection = this.quillEditors[this.activeEditorIndex].getSelection();
            const cursorPosition = selection ? selection.index : 0;
            this.quillEditors[this.activeEditorIndex].insertText(cursorPosition, token);
            this.quillEditors[this.activeEditorIndex].setSelection(cursorPosition + token.length);
        }
    }

    insertTokenInMonaco(token: string) {
        if (token) {
            const selection = this.monacoEditors[this.activeEditorIndex].getSelection();
            const range = new monaco.Range(selection.startLineNumber, selection.startColumn, selection.endLineNumber, selection.endColumn);
            const insertToken = { range, text: token, forceMoveMarkers: true };
            this.monacoEditors[this.activeEditorIndex].executeEdits('tokens', [insertToken]);
        }
    }
}

