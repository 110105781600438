<h1 class="display-2"> BEM
</h1>

<section class="sg-section">
    <h2 class="h3">Intro</h2>
    <div class="row sg-section_content">
        <div class="col-6">
            <p>
                BEM (Block, Element, Modifier) is a component-based approach to web development. The idea behind it is
                to divide the user interface into independent blocks. This makes interface development easy and fast
                even with a complex UI, and it allows reuse of existing code without copying and pasting.
            </p>
        </div>
    </div>

</section>
<section class="sg-section">
    <h2 class="h3">Block</h2>
    <div class="row sg-section_content">
        <div class="col-6">
            <p>
                A functionally independent page component that can be reused. In HTML, blocks are represented by the
                class attribute.
            </p>
            <br/>
            <p>
                The block name describes its purpose ("What is it?" — menu or
                button), not its state ("What does it look like?" — red or big).
            </p>
            <ul>
                <li>The block shouldn't influence its environment, meaning you shouldn't set the external geometry ( margin ) or positioning for the block.
                </li>
                <li>You also shouldn't use CSS tag or ID selectors when using BEM.</li>
            </ul>
            <p>This ensures the necessary independence for reusing blocks or moving them from place to place.</p>
            <br/>
            <h3 class="h5">Nesting</h3>
            <ul>
                <li>Blocks can be nested in each other.</li>
                <li>You can have any number of nesting levels.</li>
            </ul>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">Element</h2>
    <div class="row sg-section_content">
        <div class="col-6">
            <p>A composite part of a block that can't be used separately from it..</p>
            <ul>
                <li>
                    The element name describes its purpose ("What is this?" — item, text, etc.), not its state ("What type, or what does it look like?" — red, big, etc.).
                </li>
                <li>The structure of an element's full name is <code>block-name_element-name</code>. The
                    element name is separated from the block name with a underscore ( <code>_</code> ).
                </li>
            </ul>
            <h3 class="h5">Nesting</h3>
            <ul>
                <li>Elements can be nested inside each other.</li>
                <li>You can have any number of nesting levels.</li>
                <li>An element is always part of a block, not another element. This means that element names
                    <strong>can't</strong> define a hierarchy such as <code>block_elem1_elem2</code>.
                </li>
            </ul>
            <p>A block can have a nested structure of elements in the DOM tree.</p>
            <p>However, this block structure is always represented in CSS as a flat list of elements in the BEM
                methodology:
            </p>
            <h3 class="h5">Membership</h3>
            <ul>
                <li>An element is always part of a block, and you shouldn't use it separately from the block.</li>
            </ul>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">Modifier</h2>
    <div class="row sg-section_content">
        <div class="col-6">
            <p>An entity that defines the appearance, state, or behavior of a block or element.</p>
            <p>
                <strong>We are using
                    <span class="-highlighted">No namespace style</span>
                    naming scheme</strong></p>
            <p>This style is characterized by adding dash before modifier.<code>-modifier</code></p>
            <br/>
            <h3 class="h5">Features:</h3>
            <ul>
                <li>The modifier name describes its appearance ("What size?" or "Which theme?" and so on:
                    <code>-size_s</code>
                    or <code>-theme_islands</code>), its state ("How is it different from the others?" —
                    <code>-disabled</code>,
                    <code>-focused</code>, etc.) and its behavior ("How does it behave?" or "How does it
                    respond to
                    the user?" — such as <code>-directions_left-top</code>).
                </li>
                <li>The modifier name is separated from the block or element name by a single dash ( <code>-</code>
                    ).
                </li>
            </ul>
            <br/>
            <h3 class="h5">Types:</h3>
            <h4 class="h6">Boolean</h4>
            <p>Used when only the presence or absence of the modifier is important, and its value is irrelevant. For
                example,
                <code>-disabled</code>. If a Boolean modifier is present, its value is assumed to be true.
            </p>
            <p>The structure of the modifier's full name follows the pattern:</p>
            <ul>
                <li><code>class="block-name -modifier-name"</code></li>
                <li><code>class="block_element -modifier-name"</code></li>
            </ul>
            <h4 class="h6">Key-value</h4>
            <p>Used when the modifier value is important. For example, "a menu with the islands design theme":
                <code>-menu-theme_islands</code>.
            </p>
            <p>The structure of the modifier's full name follows the pattern:</p>
            <ul>
                <li><code>class="block-name -modifier-name_value"</code></li>
                <li><code>class="block_element -modifier-name_value"</code></li>
            </ul>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">Flip.to usage</h2>
    <div class="row sg-section_content">
        <div class="col-6">
            <p>
                In addition to what is written above, let's add <code>ft2-</code> prefix for each block and element (not modifier),
                so we all know that these are styles from new version of our styleguide!
            </p>
            <br>
            <pre><code [highlight]="example"></code></pre>
        </div>
    </div>
</section>
