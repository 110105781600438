<ul class="sg-menu">
    <li *ngFor="let item of menu">
        <a class="sg-menu_link" [routerLink]="item.url" [routerLinkActive]="'-active'" [class.-disabled]="item.submenu">
            {{item.name}}
        </a>
        <ul class="sg-submenu" *ngIf="item.submenu">
            <li *ngFor="let subitem of item.submenu">
                <a class="sg-menu_link sg-submenu_link" [routerLink]="subitem.url" [routerLinkActive]="'-active'">
                    {{subitem.name}}
                </a>
            </li>
        </ul>
    </li>
</ul>

