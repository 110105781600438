import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScatterplotComponent } from './components/scatterplot/scatterplot.component';
import { MapComponent } from './components/map/map.component';
import { ZoomMapComponent } from './components/zoom-map/zoom-map.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ScatterplotComponent, MapComponent, ZoomMapComponent],
    exports: [ScatterplotComponent, MapComponent, ZoomMapComponent]
})
export class D3Module {
}
