import {
    Component,
    ContentChild,
    EventEmitter, HostListener,
    Input,
    OnChanges, OnInit,
    Output,
    SimpleChanges,
    TemplateRef, ViewChild,
} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DateRange } from "../../models/date-range.model";
import * as moment_ from 'moment'; // fix from https://github.com/jvandemo/generator-angular2-library/issues/221
const moment = moment_;

@Component({
    selector: 'ft-daterange-picker',
    templateUrl: './daterange-picker.component.html',
    styleUrls: ['./daterange-picker.component.scss'],
    animations: [
        trigger('toggleRangePicker', [
            state('*', style({
                opacity: '1',
                marginTop: '0'
            })),
            state('void', style({
                opacity: '0',
                marginTop: '-20px'
            })),
            transition(':enter', animate('0.3s cubic-bezier(0, 0, 0.1, 1)')),
            transition(':leave', animate('0.1s 0.1s cubic-bezier(0, 0, 0.1, 1)')),
        ]),
        trigger('toggleCalendar', [
            state('true', style({
                width: '*',
            })),
            state('false', style({
                width: '0',
            })),
            transition('false <=> true', animate(300))
        ]),
    ]
})
export class DaterangePickerComponent implements OnChanges, OnInit {
    hoveredDate: NgbDate;
    dateFormat = 'yyyy-MM-dd';
    ngbFromDate: NgbDate;
    ngbToDate: NgbDate;
    @Input() storageKey: string;
    @Input('from') fromDate: string | Date;
    @Input('to') toDate: string | Date;
    @Input() fullWidth = false;
    @Input() pastDays = 30;
    @Input() rangeLimit: number;
    @Input() darkMode = false;
    @Input() inlineMode = false;
    @Input() durationVisible = true;
    @Input() datesVisible = true;
    @Input() futureDisabled = false;
    @Input() pastDisabled = false;
    @Input() hidden = false;
    @Input() verticalAppearance: 'bottom' | 'top' = 'bottom';
    @Input() horizontalAppearance: 'left' | 'center' | 'right' = 'center';
    @Output() changed = new EventEmitter<DateRange>();
    dateRange: DateRange;
    isCalendarVisible = false;
    isRangePickerVisible = false;
    @ContentChild('placeholderTmpl', {static: false}) placeholderTmpl: TemplateRef<any>;
    @ContentChild('valueTmpl', {static: false}) valueTmpl: TemplateRef<any>;
    @ViewChild('dp') datepicker: NgbDatepicker;
    durationLabel: string;
    datesLabel: string;
    fromInputError: string = null;
    toInputError: string = null;

    constructor(private calendar: NgbCalendar) {
    }


    ngOnInit() {
        this.initDates();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    @HostListener("click", ['$event']) handleClick(event) {
        if (!this.isRangePickerVisible) {
            this.openDateRangePicker();
            event.stopPropagation();
        }
    }

    initDates() {
        if (this.storageKey) {
            this.initDatesFromStore();
        }
        if (this.fromDate && this.toDate) {
            this.ngbFromDate = this.dateToNgbDate(this.fromDate);
            this.ngbToDate = this.dateToNgbDate(this.toDate);
            this.initPastDays();
        } else if (this.pastDays) {
            this.initDatesByPastDays();
        } else {
            return;
        }
        this.onRangeSelected();
    }

    initDatesFromStore() {
        try {
            const storedRange = JSON.parse(sessionStorage.getItem(`flipto.${this.storageKey}.daterange`));
            if (storedRange && storedRange.toDate && storedRange.fromDate) {
                this.toDate = storedRange.toDate;
                this.fromDate = storedRange.fromDate;
            } else {
                sessionStorage.removeItem(`flipto.${this.storageKey}.daterange`);
            }
        } catch (e) {
            sessionStorage.removeItem(`flipto.${this.storageKey}.daterange`);
        }
    }

    storeDateRange() {
        if (this.storageKey) {
            sessionStorage.setItem(`flipto.${this.storageKey}.daterange`, JSON.stringify(this.dateRange));
        }
    }

    updateLabels() {
        const duration = this.dateRange.duration;
        this.durationLabel = this.isTillToday() ? 'Past ' : '';
        this.durationLabel += duration + (duration === 1 ? ' day' : ' days');
        const isSameYear = this.ngbToDate.year === this.ngbFromDate.year;
        this.datesLabel = `${moment(this.fromDate).format(isSameYear ? 'MMM DD' : 'MMM DD, YYYY')} - ${moment(this.toDate).format('MMM DD, YYYY')}`;
    }

    openDateRangePicker() {
        this.isRangePickerVisible = true;
    }

    onCancel() {
        this.closeCalendar();
        this.closeDaterangePicker();
    }

    closeDaterangePicker() {
        this.isRangePickerVisible = false;
        this.closeCalendar();
    }

    openCalendar() {
        this.pastDays = null;
        this.isCalendarVisible = true;
    }

    closeCalendar() {
        this.isCalendarVisible = false;
    }

    initPastDays() {
        if (this.isTillToday()) {
            this.pastDays = moment(this.toDate).diff(this.fromDate, 'days') + 1;
        } else {
            this.pastDays = null;
        }
    }

    initDatesByPastDays() {
        this.ngbToDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
        this.ngbFromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', this.pastDays);
    }

    changePastDays() {
        this.initDatesByPastDays();
        this.closeCalendar();
        this.onRangeSelected();
    }

    onRangeSelected() {
        this.fromDate = this.ngbDateToDate(this.ngbFromDate);
        this.toDate = this.ngbDateToDate(this.ngbToDate);
        this.closeDaterangePicker();
        this.dateRange = new DateRange(this.fromDate, this.toDate);
        this.storeDateRange();
        this.updateLabels();
        this.changed.next(this.dateRange);
    }

    dateToNgbDate(date: string | Date) {
        let year;
        let month;
        let day;
        [year, month, day] = moment(date).format('YYYY-MM-DD').split('-');
        return new NgbDate(
            +year,
            +month,
            +day
        );
    }

    ngbDateToDate(date: NgbDate) {
        const year = date.year;
        const month = ('0' + date.month).slice(-2);
        const day = ('0' + date.day).slice(-2);
        return `${year}-${month}-${day}`;
    }

    setInputFromDate(date: string) {
        if (date) {
            const ngbDate = this.dateToNgbDate(date);
            this.ngbFromDate = null;

            if (this.isLimitedByFuture(ngbDate)) {
                this.showValidationError('fromInputError', 'future');
            } else if (this.isLimitedByPast(ngbDate)) {
                this.showValidationError('fromInputError', 'past');
            } else if (this.isLimitedByRange(ngbDate) || ngbDate.after(this.ngbToDate)) {
                this.showValidationError('fromInputError', 'range');
            } else {
                this.ngbFromDate = ngbDate;
                this.fromInputError = null;
            }

            this.navigateToStartDate();
            this.datepicker.focus();
        }
    }

    setInputToDate(date: string) {
        if (date) {
            const ngbDate = this.dateToNgbDate(date);
            this.ngbToDate = null;

            if (this.isLimitedByFuture(ngbDate)) {
                this.showValidationError('toInputError', 'future');
            } else if (this.isLimitedByPast(ngbDate)) {
                this.showValidationError('toInputError', 'past');
            } else if (this.isLimitedByRange(ngbDate) || ngbDate.before(this.ngbFromDate)) {
                this.showValidationError('toInputError', 'range');
            } else {
                this.ngbToDate = ngbDate;
                this.toInputError = null;
            }
            this.navigateToEndDate();
            this.datepicker.focus();
        }
    }

    onDateSelection(date: NgbDate) {
        if (!this.isDisabled(date)) {
            if (!this.ngbFromDate && !this.ngbToDate) {
                this.ngbFromDate = date;
            } else if (this.ngbFromDate && !this.ngbToDate) {
                this.ngbToDate = date;
            } else if (this.ngbToDate && !this.ngbFromDate) {
                this.ngbFromDate = date;
            } else {
                this.ngbFromDate = date;
                this.ngbToDate = null;
            }
        }
    }

    isHovered(date: NgbDate) {
        return this.ngbFromDate && !this.ngbToDate && this.hoveredDate && date.after(this.ngbFromDate) && date.before(this.hoveredDate)
            || !this.ngbFromDate && this.ngbToDate && this.hoveredDate && date.before(this.ngbToDate) && date.after(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.ngbFromDate) && date.before(this.ngbToDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.ngbFromDate) || date.equals(this.ngbToDate) || this.isInside(date) || this.isHovered(date);
    }

    isDisabled(date: NgbDate) {
        if (!date) {
            return false;
        }
        if (this.isLimitedByRange(date)) {
            return true;
        } else if (this.isLimitedByFuture(date)) {
            return true;
        } else if (this.isLimitedByPast(date)) {
            return true;
        }
        return false;
    }

    isLimitedByRange(date: NgbDate) {
        if (!this.rangeLimit || !date) {
            return false;
        }
        if (this.ngbFromDate && !this.ngbToDate) {
            const limitDate = this.getFutureLimitDate();
            return date.after(limitDate);
        }
        if (!this.ngbFromDate && this.ngbToDate) {
            const limitDate = this.calendar.getPrev(this.ngbToDate, 'd', this.rangeLimit - 1);
            return date.before(limitDate);
        }
        return false;
    }

    isLimitedByFuture(date: NgbDate) {
        if (this.futureDisabled) {
            return date.equals(this.calendar.getToday()) || date.after(this.calendar.getToday());
        }
        return false;
    }

    isLimitedByPast(date: NgbDate) {
        if (this.rangeLimit && this.pastDisabled) {
            const pastLimitDate = this.getPastLimitDate();
            return date.before(pastLimitDate);
        }
        return false;
    }

    isTillToday() {
        const yesterday = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
        return this.ngbToDate.equals(yesterday);
    }

    getPastLimitDate() {
        return this.calendar.getPrev(this.calendar.getToday(), 'd', this.rangeLimit);
    }

    getFutureLimitDate() {
        const yesterday = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
        if (!this.ngbFromDate || !this.rangeLimit) {
            return yesterday;
        }
        const limitDate = this.calendar.getNext(this.ngbFromDate, 'd', this.rangeLimit - 1);
        if (this.futureDisabled && limitDate.after(yesterday)) {
            return yesterday;
        } else {
            return limitDate;
        }
    }

    navigateToStartDate() {
        if (this.datepicker && this.ngbFromDate) {
            this.datepicker.navigateTo(this.ngbFromDate);
        }
    }

    navigateToEndDate() {
        if (this.datepicker && this.ngbToDate) {
            this.datepicker.navigateTo(this.calendar.getPrev(this.ngbToDate, 'd', 31));
        }
    }

    onHover(date) {
        this.hoveredDate = date;

        if (this.ngbFromDate && !this.ngbToDate) {
            if (date.before(this.ngbFromDate)) {
                this.ngbToDate = this.ngbFromDate;
                this.ngbFromDate = null;
            }
        }

        if (!this.ngbFromDate && this.ngbToDate) {
            if (date.after(this.ngbToDate)) {
                this.ngbFromDate = this.ngbToDate;
                this.ngbToDate = null;
            }
        }
    }

    showValidationError(errorDist: string, errorKey: string) {
        setTimeout(() => {
            this[errorDist] = this.validationErrors[errorKey];
        }, 100);
    }

    get validationErrors() {
        return {
            range: `Allowed date range limit is ${this.rangeLimit} days.`,
            future: 'Unable to select date from future',
            past: 'Unable to select date from past'
        };
    }
}
