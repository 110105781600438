import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

@Component({
    selector: 'ft-dropzone-uploader-editor',
    templateUrl: './dropzone-uploader-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DropzoneUploaderEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: DropzoneUploaderEditorComponent
        }
    ]
})
export class DropzoneUploaderEditorComponent extends ModalEditorBase {
    @Input() accept = '*';
    @Input() imagePreview = true;
    @Input() multiple = false;

    constructor() {
        super();
    }

    onSelect(files: File[]) {
        this.value$.next(this.multiple ? files : files[0]);
    }

    isInvalid() {
        return !this.value;
    }
}

