import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import * as topo from 'topojson';
import { D3MapPoint } from '../../models/d3-map-point';
import { D3MapUnit } from '../../enums/d3-map-unit.enum';
import { D3MapDistanceScale } from '../../models/d3-map-unit-scale';


@Component({
    selector: 'd3-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, OnChanges {
    svg;
    projection;
    @Input() width = 600;
    @Input() height = 600;
    @Input() mapJsonUrl: string;
    @Input() radial = false;
    @Input() units: D3MapUnit = D3MapUnit.mile;
    @Input() distanceScales: D3MapDistanceScale[];
    @Input() points: D3MapPoint[] = [];
    @Input() centerPoint: D3MapPoint;
    @Input() selectedPointId: any;
    @Input() radius = 200;
    loading = true;
    scale: number;
    tooltip: any;
    PIXEL_ON_THOUSAND_SCALE_IN_KM = 5.3167;
    PIXEL_ON_THOUSAND_SCALE_IN_MI = 3.3036;

    constructor(private hostElement: ElementRef) {
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedPointId) {
            if (this.selectedPointId) {
                this.highlightPointById(this.selectedPointId);
            } else {
                this.resetHighlight();
            }
        }
    }

    resetHighlight() {
        d3.selectAll('.d3-map-point').classed('-inactive', false);
        d3.selectAll('.d3-map-point').classed('-active', false);
        this.onMouseout();
    }

    highlightPointById(pointId: any) {
        d3.selectAll('.d3-map-point').classed('-inactive', true);
        const selection = d3.select('#' + pointId);
        selection.classed('-active', true).classed('-inactive', false);
        const found = this.points.find(p => p.id === pointId);
        this.onMouseover(found);
    }

    pixelDistance(value: number) {
        const scaleFactor = this.scale / 1000;
        return value / (this.unitFactor / scaleFactor);
    }

    calcScale(): number {
        return this.width * this.unitFactor * 1000 / (this.radius * 2);
    }

    get unitFactor() {
        if (this.units === D3MapUnit.kilometer) {
            return this.PIXEL_ON_THOUSAND_SCALE_IN_KM;
        } else {
            return this.PIXEL_ON_THOUSAND_SCALE_IN_MI;
        }
    }

    async init() {
        this.createSvg();
        this.createTooltip();
        await this.drawMap();
        this.drawDistanceScales();
        this.createCities();
    }

    createSvg() {
        this.svg = d3.select(this.hostElement.nativeElement.children[0]).append('svg');
        this.svg.attr('viewBox', `0 0 ${this.width} ${this.height}`);
    }

    drawDistanceScales() {
        this.distanceScales?.forEach(distanceScale => {
            const pixelDistance = this.pixelDistance(distanceScale.value);
            this.drawDistanceScale(pixelDistance, distanceScale.label);
        });
    }

    drawDistanceScale(radius: number, label: string) {
        const labelChars = label.length;
        const start = (2 * Math.PI) / radius * labelChars;
        const end = (2 * Math.PI) - (2 * Math.PI) / radius * labelChars;

        const arc = d3.arc()
            .innerRadius(radius)
            .outerRadius(radius)
            .startAngle(start)     // It's in radian, so Pi = 3.14 = bottom.
            .endAngle(end);     // 2*Pi = 6.28 = top


        this.svg.append('path')
            .attr('class', 'd3-scale')
            .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
            .attr('d', arc);

        this.svg.append('text')
            .text(label)
            .attr('fill', '#fff')
            .attr('transform', `translate(0, 5)`)
            .attr('text-anchor', 'middle')
            .attr('x', this.width / 2)
            .attr('y', this.height / 2 - radius);
    }

    drawMap(): Promise<any> {
        this.scale = this.calcScale();

        // D3 Projection
        this.projection = d3.geoMercator()
            .translate([this.width / 2, this.height / 2])
            .center([this.centerPoint.lon, this.centerPoint.lat])
            .scale(this.scale);
        // Define path generator
        const path = d3.geoPath()
            .projection(this.projection);


        return d3.json(this.mapJsonUrl).then((topology: any) => {
            this.loading = false;


            const statesGeoJson = topo.feature(topology, topology.objects.states);
            this.svg.selectAll('path')
                .data(statesGeoJson.features)
                .enter().append('path')
                .attr('d', path)
                .attr('class', 'd3-map-state');

            const countiesGeoJson = topo.feature(topology, topology.objects.counties);
            this.svg.selectAll('path')
                .data(countiesGeoJson.features)
                .enter().append('path')
                .attr('d', path)
                .attr('class', (d) => {
                    // if (d.properties.name === 'Charleston') {
                    //     return 'd3-1';
                    // }
                    return 'd3-map-county';
                });

            return Promise.resolve(true);
        }).catch((err) => {
            this.loading = false;
            return Promise.reject(err);
        });
    }

    createCities() {
        this.svg.selectAll('circle')
            .data([...this.points, this.centerPoint])
            .enter()
            .append('a')
            .append('circle')
            .attr('class', 'd3-map-point')
            .attr('cx', (d) => this.projection([d.lon, d.lat])[0])
            .attr('cy', (d) => this.projection([d.lon, d.lat])[1])
            .attr('r', (d) => d.size ? d.size : 5)
            .attr('id', (d) => d.id)
            .on('mouseover', this.onMouseover.bind(this))
            .on('mouseout', this.onMouseout.bind(this));

        this.svg.selectAll('text')
            .data([...this.points, this.centerPoint])
            .enter()
            .append('text')
            .text((d) => d.name)
            .attr('x', (d) => this.projection([d.lon, d.lat])[0] + 5)
            .attr('y', (d) => this.projection([d.lon, d.lat])[1] + 15)
            .attr('class', 'd3-map-label')
            .on('mouseover', this.onMouseover.bind(this))
            .on('mouseout', this.onMouseout.bind(this));


        this.svg.selectAll('circle');

    }

    onMouseover(d) {
        if (d.tooltip) {
            const pointNode: any = d3.select('#' + d.id).node();
            if (pointNode) {
                const rect = pointNode.getBoundingClientRect();
                const pointTop = rect.top;
                const pointLeft = rect.left;

                this.tooltip.transition()
                    .duration(300)
                    .style('opacity', 1)
                    .style('transform', 'translateY(-30px)');
                this.tooltip.html(d.tooltip)
                    .style('left', () => {
                        const tooltipWidth = this.tooltip.node().getBoundingClientRect().width;
                        return pointLeft - tooltipWidth / 2 + 'px';
                    })
                    .style('top', () => {
                        const tooltipHeight = this.tooltip.node().getBoundingClientRect().height;
                        return (pointTop - tooltipHeight + 20) + 'px';
                    });
            }

        }
    }


    onMouseout() {
        if (this.tooltip) {
            this.tooltip.transition()
                .duration(0)
                .style('transform', 'translateY(0)')
                .style('opacity', 0);
        }
    }

    createTooltip() {
        // add the tooltip area to the webpage
        this.tooltip = d3.select(this.hostElement.nativeElement).append('div')
            .attr('class', 'd3-tooltip')
            .style('opacity', 0);
    }
}
