import { Component, OnInit } from '@angular/core';
import { DateRange, EditorToken } from '@flipto/shared';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'sg-editors',
    templateUrl: './editors.component.html',
    styleUrls: ['./editors.component.scss']
})
export class EditorsComponent implements OnInit {
    text = 'Click, to edit this text';
    options = [
        {
            id: 1,
            role: 'Platform Lead',
            description: 'This person can create team members and properties and do everything an Editor can.'
        },
        {
            id: 2,
            role: 'Editor',
            description: 'This person can edit platform settings, publish content, view and edit audience personal info, and view platform stats and reports.'
        },
        {
            id: 3,
            role: 'Reviewer',
            description: 'This person can only view platform stats and reports. They can view, but not edit, other areas of the platform.'
        }
    ];
    url: string;
    email: string;
    html = `<body><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores aut blanditiis commodi ipsam nemo nihil odit perspiciatis quisquam quo quod. Et exercitationem hic in magni quibusdam quisquam rem suscipit tempore!</p></body>`;
    css = `body{font-size: 16px; color: gray;}`;
    multiText = [
        {
            language: 'English',
            greeting: '<strong>Hello</strong>'
        },
        {
            language: 'Russian',
            greeting: 'Привет'
        }
    ];

    textWithFields = {
        greeting: 'Hello',
        parting: 'Good bye'
    };

    bookingEngineUrlTokens: EditorToken[] = [
        {
            name: 'Destination booking engine code',
            value: '{destination_booking_engine_code}'
        },
        {
            name: 'Exact start date',
            value: '{exact_start_date,format=MM/dd/yyyy}'
        },
        {
            name: 'Exact end date',
            value: '{exact_end_date,format=MM/dd/yyyy}'
        },
        {
            name: 'Rooms',
            value: '{rooms}'
        },
        {
            name: 'Adults',
            value: '{adults}'
        },
        {
            name: 'Children',
            value: '{children}'
        }
    ];

    toggled = false;
    textArray = ['one', 'two', 'three'];
    textArraySelected = ['one'];

    dateRange = new DateRange('08-04-2019', '08-04-2020');
    newDateRange = null;

    selectedRole;
    selectedRoles = [];
    firstName = 'John';
    lastName = 'Doe';
    saveSuccess$ = new Subject();
    error$ = new Subject();
    color = '#4F47DC';
    img = '';

    constructor() {
    }

    ngOnInit(): void {
    }

    onSave(data) {
        setTimeout(() => {
            // this.saveSuccess$.next();
            this.error$.next('Error!');
            alert(JSON.stringify(data));
        }, 1000);
    }

    onSelect(data) {
        alert(`Selected role id ${data.role}`);
    }

}
