import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

@Pipe({
    name: 'ngbDate'
})
export class NgbDatePipe implements PipeTransform {
    locale: string;

    constructor(@Inject(LOCALE_ID) locale: string) {
        this.locale = locale;
    }

    transform(ngbDate: NgbDate, format: string = 'yyyy-MM-dd'): string {
        if (ngbDate && ngbDate.year && ngbDate.month && ngbDate.day) {
            const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
            return new DatePipe(this.locale).transform(date, format);
        } else {
            return null;
        }
    }
}
