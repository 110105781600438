import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sg-root',
    templateUrl: './styleguide.component.html',
    styleUrls: ['./styleguide.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StyleguideComponent {
    title = 'styleguide';

    constructor() {
    }
}
