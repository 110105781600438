<nav class="ft2-journey-nav">
    <ul class="ft2-journey-group">
        <li class="ft2-journey-card">
            <div>
                <h2 class="display-3">
                    Planner
                </h2>
                <p class="ft2-journey-card_summary">
                    Give website visitors a better way to discover how you’d be the perfect fit for their plans. You’ll
                    get more opportunities to nurture prospects towards conversion and truly personalize their
                    experience every step of the way.
                </p>
            </div>
            <div class="ft2-journey-card_person">
                <h2 class="h5 uppercase mb-1">
                    <strong>MEET WMU49L</strong>
                </h2>
                <p class="bold-text mb-3">
                    That's an anonymous site visitor.
                </p>
                <div class="ft2-journey-circle -blue">
                    <div class="ft2-journey-circle_profile">
                        Mary
                    </div>
                </div>
            </div>
        </li>
    </ul>
</nav>
<section class="ft2-journey-section -large -path_blue">
    <div class="ft2-container -narrow text-center">
        <div class="ft2-journey-section_content ft2-bg-striped p-5">
            <p class="h5 bold-text mb-3">This feature is not active</p>
            <p>
                Your website visitors will not see this journey until you activate your Planner Flow touchpoint below.
            </p>
        </div>
    </div>
</section>
<section class="ft2-journey-section -small ">
    <div class="ft2-container">
        <div class="row">
            <div class="col-5 col-md-12 text-content">
                <p>
                    Discovery is made of several components that work together. Configure site-wide settings that should
                    be available across all components.
                </p>
                <div class="ft2-touchpoint-entry">
                    <mat-icon [svgIcon]="'touchpoint'"></mat-icon>
                    Discovery Component
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ft2-journey-section">
    <div class="ft2-container">
        <div class="row v-center">
            <div class="col-7 col-md-12 ft2-perspective">
                <div class="ft2-screenshot ft2-browser -small -skew_left">
                    <img alt="" src="https://localhost:44300/dist/v2-hybrid/ft-screen-email-prearrival-banner.jpg">
                </div>
            </div>
            <div class="col-5 col-md-12 text-content mt-md-6">
                <p>
                    Augment your website with a pre-booking flow that captures important signals as your visitor chooses
                    their options. Discovery components sets up the beginning of this process, providing smart triggers
                    to the experience that follows.
                </p>
                <div class="ft2-touchpoint-entry">
                    <mat-icon [svgIcon]="'touchpoint'"></mat-icon>
                    Booking Button
                </div>
                <div class="ft2-touchpoint-entry">
                    <mat-icon [svgIcon]="'touchpoint'"></mat-icon>
                    Booking Bar
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ft2-journey-section">
    <div class="ft2-container">
        <div class="row v-center">
            <div class="col-5 col-md-12 text-content mb-md-6">
                <p>
                    The Discovery Experience gathers basic information about your visitor’s travel plan and goes a step
                    further by asking the visitor to unveil before redirecting to the booking engine. This simple
                    introduction expands the possibilities for nurturing your prospect.
                </p>
                <div class="ft2-touchpoint-entry">
                    <mat-icon [svgIcon]="'touchpoint'"></mat-icon>
                    Planner Flow
                </div>
            </div>
            <div class="col-7 col-md-12 ft2-perspective">
                <div class="ft2-screenshot ft2-browser -small -skew_right">
                    <img alt="" src="https://localhost:44300/dist/v2-hybrid/ft-screen-email-prearrival-banner.jpg">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="ft2-journey-section -large text-center">
    <div class="ft2-journey-label -striped">
        Conversion point
    </div>
    <section class="ft2-section -large -skewed -red my-0 pt-7">
        <div class="ft2-container -narrow">
            <div class="ft2-journey-circle -pure">
                <div class="ft2-journey-circle_profile">
                    Mary
                </div>
            </div>
            <div class="text-content mt-4">
                <p class="h1">
                    Mary reveals herself and her travel intent.
                </p>
                <p>
                    When UID6789012 introduces herself, she’s unveiling a real intent to travel long before she hits the
                    final transaction. We can now engage Mary with a warmer conversation throughout her planning phase.
                </p>
            </div>
        </div>
    </section>
</div>


<section class="ft2-section ft2-journey-section">
    <div class="ft2-container">
        <div class="row v-center h-center f-sm-column">
            <div class="ft2-journey-section_content flex-1">
                <p class="p-3 text-right text-sm-center">
                    If she’s ready at this point, Mary can complete her booking and look forward to her trip.
                </p>
            </div>
            <div class="ft2-journey-circle -striped m-4">
                <mat-icon class="ft2-journey-circle_icon text-gray-200" [svgIcon]="'check'"></mat-icon>
            </div>
            <div class="ft2-journey-section_content flex-1 text-sm-center">
                <p class="p-3 text-sm-center">
                    Otherwise, she’ll continue the remainder of the journey below.
                </p>
            </div>
        </div>
    </div>
</section>


<section class="ft2-section -large ft2-journey-section pb-0 mb-7">
    <div class="ft2-container -narrow">
        <div class="ft2-journey-frame">
            <p class="solid-text">
                Next up...
            </p>
            <p class="display-3">
                Pre-stay
            </p>
            <p class="mt-4 mb-2">
                Mary’s journey continues after she completes her transaction.
            </p>
        </div>
    </div>
</section>
