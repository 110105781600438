import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ft-upload-area',
    templateUrl: './upload-area.component.html',
    styleUrls: ['./upload-area.component.scss']
})
export class UploadAreaComponent implements OnInit {
    @Input() width = 50;
    @Input() height = 50;
    @Input() borderWidth = 1;
    @Input() borderStyle = 'solid';
    @Input() isUploading: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
