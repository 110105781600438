import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreasDirective } from './areas.directive';
import {
  AREAS_REGISTRY,
  HandlerRegistryItem,
  RegistryItem,
  AreasRegistry,
  StateRegistryItem,
  MultipleOptionsRegistryItem
} from "./areas.service";
import { AreasRegistryContext } from './areas-registry-context';

@NgModule({
  declarations: [AreasDirective],
  imports: [
    CommonModule
  ],
  exports: [AreasDirective],
  entryComponents: []
})
class AreasModule { }

export {
  AreasModule,
  AreasDirective,
  AREAS_REGISTRY,
  HandlerRegistryItem,
  RegistryItem,
  AreasRegistry,
  StateRegistryItem,
  AreasRegistryContext,
  MultipleOptionsRegistryItem
};
