import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-selects',
  templateUrl: './selects.component.html',
  styleUrls: ['./selects.component.scss']
})
export class SelectsComponent implements OnInit {
  value: string;
  items = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven'];
  constructor() { }

  ngOnInit(): void {
  }

}
