import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StyleguideState } from "./index";

export const selectStyleguide = createFeatureSelector<State, StyleguideState>('styleguide');

export const selectStyleguideData = createSelector(
    selectStyleguide,
    (state: StyleguideState) => state.data
);

export const selectStyleguideDataLoading = createSelector(
    selectStyleguide,
    (state: StyleguideState) => state.loading
);

export const selectStyleguideText = createSelector(
    selectStyleguide,
    (state: StyleguideState) => state.text
);

export const selectStyleguideTextLoading = createSelector(
    selectStyleguide,
    (state: StyleguideState) => state.textLoading
);
