<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <input class="ft2-input -large"
           [ngModel]="value"
           (ngModelChange)="onModelChange($event)"
           [placeholder]="placeholder"
           [required]="required"
           [min]="min"
           [max]="max"
           [minlength]="minlength"
           [maxlength]="maxlength"
           [email]="email"
           [pattern]="pattern"
           [name]="name"
           #inputElement
           #control="ngModel"
           [type]="type">
    <div class="ft2-form-error mt-2" *ngIf="control.invalid && control.touched">
        <p *ngIf="control.errors.required">
            Value is required.
        </p>
        <p *ngIf="control.errors.minlength">
            Value must be at least {{minlength}} characters long.
        </p>
        <p *ngIf="control.errors.maxlength">
            Value must be at most {{maxlength}} characters long.
        </p>
        <p *ngIf="control.errors.email">
            Value is not valid email address.
        </p>
        <p *ngIf="control.errors.pattern">
            {{patternValidationText ? patternValidationText : 'Value doesn not match pattern.' + pattern}}
        </p>
        <p *ngIf="control.errors.min">
            Value can't be less then {{min}}
        </p>
        <p *ngIf="control.errors.max">
            Value can't be more then {{max}}
        </p>
    </div>
</ft-modal-editor-wrapper>
