<section class="ft2-section -small ft2-bg-striped">
    <div class="ft2-container">
        <div class="row">
            <div class="col-8 col-sm-12">
                <ft-validation-checklist [title]="title"
                                         [summary]="summary"
                                         [rules]="rules">
                </ft-validation-checklist>
            </div>
            <div class="col-4 col-sm-12 h-end">
                <button class="ft2-btn-primary -large"
                        [disabled]="!isChecklistValid()"
                        (click)="save.next()"
                        [ftLoading]="loading$">
                    Save
                </button>
            </div>
        </div>
    </div>
</section>
