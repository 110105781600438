import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "fullName"
})
export class FullNamePipe implements PipeTransform {
    transform(source: any, isInitialOnly: boolean = false) {
        if (!source) return '';
        if (!getFirstName(source) && !getLastName(source)) return '';
        return formatName(source, isInitialOnly);
    }
}


function getFirstName(source) {
    return source.first || source.FirstName || source.firstName;
}

function getLastName(source) {
    return source.last || source.LastName || source.lastName;
}

function formatName(source, isInitialOnly) {
    var name = getFirstName(source);
    if (getLastName(source)) name += ' ' + (isInitialOnly ? (getLastName(source).substring(0, 1) + ".") : getLastName(source));
    return name;
}