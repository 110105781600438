<h1 class="display-2">
    Buttons
</h1>
<section class="sg-section">
    <h2 class="h3">
        Colors
    </h2>
    <div class="row">
        <div class="col-12">
            <span class="mr-1">
                <button class="ft2-btn-default">
                    Default
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-primary">
                    Primary
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-secondary">
                    Secondary
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-danger">
                    Danger
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-warning">
                    Warning
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-dark">
                    Dark
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-light">
                    Light
                </button>
            </span>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Shapes
    </h2>
    <h3 class="h5">
        Rounded
    </h3>
    <div class="row mb-5">
        <div class="col-12">
            <span class="mr-1">
                <button class="ft2-btn-default -rounded">
                    Default
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-primary -rounded">
                    Primary
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-secondary -rounded">
                    Secondary
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-danger -rounded">
                    Danger
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-warning -rounded">
                    Warning
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-dark -rounded">
                    Dark
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-light -rounded">
                    Light
                </button>
            </span>
        </div>
    </div>
    <h3 class="h5">
        Outline
    </h3>
    <div class="row">
        <div class="col-12 v-center">
            <span class="mr-1">
                <button class="ft2-btn-default -outline">
                    Default
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-primary -outline">
                    Primary
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-secondary -outline">
                    Secondary
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-danger -outline">
                    Danger
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-warning -outline">
                    Warning
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-dark -outline">
                    Dark
                </button>
            </span>
            <div class="mr-1 bg-dark p-1">
                <button class="ft2-btn-light -outline">
                    Light
                </button>
            </div>
        </div>
    </div>
</section>
<section class="sg-section">

    <h2 class="h3">
        Sizes
    </h2>
    <div class="row">
        <div class="col-12">
            <span class="mr-1">
                <button class="ft2-btn-primary -small">
                    Small
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-primary">
                    Default
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-primary -large">
                    Large
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-primary -huge">
                    Huge
                </button>
            </span>
        </div>
        <div class="col-4 mt-2">
            <button class="ft2-btn-primary -wide">
                Wide
            </button>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Social buttons
    </h2>
    <div class="row">
        <div class="col-12">
            <span class="mr-1">
                <button class="ft2-btn-facebook">
                    Facebook
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-twitter">
                    Twitter
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-linkedin">
                    Linkedin
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-instagram">
                    Instagram
                </button>
            </span>
            <span class="mr-1">
                <button class="ft2-btn-pintrest">
                    Pintrest
                </button>
            </span>
        </div>
    </div>
</section>
<h2 class="h3">
    Button group
</h2>
<section class="sg-section">
    <div class="row">
        <div class="col-3 mb-4">
            <div class="ft2-btn-group">
                <button class="ft2-btn">
                    Button
                </button>
                <button class="ft2-btn -active">
                    Active
                </button>
                <button class="ft2-btn">
                    Button
                </button>
            </div>
        </div>
        <div class="col-3 mb-4">
            <div class="ft2-btn-group">
                <button class="ft2-btn -large">
                    Button
                </button>
                <button class="ft2-btn -large -active">
                    Active
                </button>
                <button class="ft2-btn -large">
                    Button
                </button>
            </div>
        </div>
        <div class="col-3 mb-4">
            <div class="ft2-btn-group -vertical">
                <button class="ft2-btn">
                    Button
                </button>
                <button class="ft2-btn -active">
                    Active
                </button>
                <button class="ft2-btn">
                    Button
                </button>
            </div>
        </div>
        <div class="col-3 mb-4">
            <div class="ft2-btn-group -vertical">
                <button class="ft2-btn -large">
                    Button
                </button>
                <button class="ft2-btn -large -active">
                    Active
                </button>
                <button class="ft2-btn -large">
                    Button
                </button>
            </div>
        </div>
    </div>
</section>
<section class="sg-section">
    <h2 class="h3">
        Other
    </h2>
    <div class="row">
        <div class="col-12">
            <button class="ft2-btn-text">
                Text button
            </button>
        </div>
    </div>
</section>

