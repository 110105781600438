import { createAction, props } from "@ngrx/store";

export const loadData = createAction('[Styleguide] Load Data');

export const loadDataFailure = createAction(
    '[Styleguide] Load Data Failure',
    props<{ error: any }>()
);

export const loadDataSuccess = createAction(
    '[Styleguide]  Load Data Success',
    props<{ data: any }>()
);

export const changeData = createAction(
    '[Styleguide] Change Data',
    props<{ changes: any }>()
);

export const loadText = createAction('[Styleguide] Load Text');

export const loadTextFailure = createAction(
    '[Styleguide] Load Text Failure',
    props<{ error: any }>()
);

export const loadTextSuccess = createAction(
    '[Styleguide]  Load Text Success',
    props<{ text: any }>()
);

export const changeText = createAction(
    '[Styleguide] Change Text',
    props<{ changes: any }>()
);
