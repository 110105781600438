<div class="ft2-edit-panel"
     [class.-disabled]="disabled"
     [class.-loading]="loading"
     [class.-read-only]="readOnly"
     [class.-static]="static">
    <div class="ft2-edit-panel_label"
         [class.-fluid]="fluidLabel"
         *ngIf="label || summary">
        {{label}}:
        <p class="ft2-edit-panel_summary">
            {{summary}}
        </p>
    </div>
    <ng-content></ng-content>
    <div class="ft2-edit-panel_error" *ngIf="error">
        <small class="v-center">
            {{error?.message || 'Error processing request'}}
        </small>
    </div>
    <span *ngIf="loading" class="ft2-edit-panel_spinner ft2-spinner"></span>
</div>

