<div class="ft2-loading" [class.-inline]="inlineMode">
    <p *ngIf="(loadingSubscription$ | async) else error">
        {{loadingText}}
        <span class="ft2-spinner"></span>
    </p>
    <ng-template #error>
        <p *ngIf="(errorSubscription$ | async) as err else empty">
            {{err?.status === 404 ? error404Text : errorText}}
        </p>
    </ng-template>
    <ng-template #empty>
        <p *ngIf="data$ && !(data$ | async) || (isArray(data$ | async) && (data$ | async).length === 0)">
            <ng-content>
            </ng-content>
        </p>
    </ng-template>
</div>
