import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ft-color-swatch',
    templateUrl: './color-swatch.component.html',
    styleUrls: ['./color-swatch.component.scss']
})
export class ColorSwatchComponent implements OnInit {
    @Input() color: string;
    @Input() placeholder = 'Click to edit';

    constructor() {
    }

    ngOnInit(): void {
    }

}
