import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ft-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {
    @Input() mode: 'img' | 'background' = 'img';
    @Input() src: string;
    @Input() placeholder = 'Upload an image';
    @Input() width = '100%';
    @Input() height = 'auto';
    @Input() darkBg = false;
    @Input() removable = false;
    @Input() frame = true;
    @Output() remove = new EventEmitter();

    constructor() {
    }
}
