<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-editor-list-content">
        <div class="v-center" *ngFor="let option of options; let i = index">
            <input class="ft2-radio"
                   [id]="'radio-editor-' + i"
                   type="radio"
                   [ngModel]="value"
                   (ngModelChange)="onModelChange($event)"
                   [name]="name"
                   #control="ngModel"
                   [required]="required"
                   [value]="option[bindValue] ? option[bindValue] : option">
            <label [for]="'radio-editor-' + i">
                <span class="bold-text">
                    {{option[bindLabel] ? option[bindLabel] : option}}
                </span>
                <p class="text-muted" *ngIf="option[bindSummary]">{{option[bindSummary]}}</p>
            </label>
        </div>
    </div>
</ft-modal-editor-wrapper>
