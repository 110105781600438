import { OnInit, ElementRef, Component, Output, EventEmitter } from '@angular/core';
import { IntersectionObserverComponent } from './intersection-observer.component';

@Component({
    selector: 'ft-intersection-observable,[ft-intersection-observable]',
    template: "<ng-content></ng-content>"
})
export class IntersectionObservableComponent implements OnInit {

    isIntersecting = false;

    @Output() onIsIntersecting = new EventEmitter<boolean>();

    constructor(private intersectionObserver: IntersectionObserverComponent,
        private elem: ElementRef) { }

    ngOnInit() {
        this.intersectionObserver.addObservable(this.element, (isIntersecting) => {
            this.isIntersecting = isIntersecting;
            this.onIsIntersecting.emit(this.isIntersecting);
        });
    }

    get element() {
        return this.elem.nativeElement;
    }

}
