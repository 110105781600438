<div cdkDropList
     class="overflow-hidden py-1"
     (cdkDropListDropped)="drop($event)"
     [cdkDropListData]="data"
     [cdkDropListConnectedTo]="'ft-tree'"
     id="ft-tree">
    <ft-tree-node
            cdkDrag
            #node
            (remove)="removeNode(section)"
            (cdkDragStarted)="node.collapse()"
            class="d-block "
            *ngFor="let section of data">
        {{section.name}}
        <ng-template #node>
            <ng-container
                    [ngTemplateOutlet]="sectionTmpl"
                    [ngTemplateOutletContext]="{section:section}">
            </ng-container>
        </ng-template>
        <ng-template #children>
            <div cdkDropList
                 class="py-1"
                 [id]="section.id"
                 [cdkDropListData]="section.destinations"
                 (cdkDropListDropped)="drop($event)"
                 [cdkDropListConnectedTo]="sectionsIds">
                <ft-tree-node
                  #node
                  (cdkDragStarted)="node.collapse()"
                  (remove)="removeNode(destination)"
                  cdkDrag
                  *ngFor="let destination of section.destinations">
                    {{destination.name}}
                    <ng-template #node>
                        <ng-container
                                [ngTemplateOutlet]="destinationTmpl"
                                [ngTemplateOutletContext]="{destination:destination}">
                        </ng-container>
                    </ng-template>
                    <ng-template #children>
                        <div [id]="destination.id"
                             class="py-1"
                             cdkDropList
                             [cdkDropListData]="destination.properties"
                             [cdkDropListConnectedTo]="destinationsIds"
                             (cdkDropListDropped)="drop($event)">
                            <ft-tree-node cdkDrag
                                          #node
                                          (remove)="removeNode(property)"
                                          (cdkDragStarted)="node.collapse()"
                                          *ngFor="let property of destination.properties">
                                {{property.name}}
                                <ng-template #node>
                                    <ng-container
                                            [ngTemplateOutlet]="propertyTmpl"
                                            [ngTemplateOutletContext]="{property:property}">
                                    </ng-container>
                                </ng-template>
                            </ft-tree-node>
                        </div>
                    </ng-template>
                </ft-tree-node>
            </div>
        </ng-template>
    </ft-tree-node>
</div>


<ng-template #sectionTmpl let-section="section">
    <div class="container">
        <div class="row">
            <div class="col-5">
                <div class="row my-1">
                    <div class="col-4">
                        <span class="bold-text">Section name:</span>
                    </div>
                    <div class="col-8">
                        <ft-input-editor class="v-center" [(ngModel)]="section.name">
                            <span class="v-center">
                                {{section.name}}
                                <mat-icon class="muted-text ml-1">edit</mat-icon>
                            </span>
                        </ft-input-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #propertyTmpl let-property="property">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <div class="row my-1">
                    <div class="col-5">
                        <span class="bold-text">Change property:</span>
                    </div>
                    <div class="col-7">
                        <ft-select-editor class="v-center" [(ngModel)]="property.name">
                            <span class="v-center">
                                {{property.name}}
                                <mat-icon class="muted-text ml-1">edit</mat-icon>
                            </span>
                        </ft-select-editor>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row my-1">
                    <div class="col-4">
                        <span class="bold-text">Be code:</span>
                    </div>
                    <div class="col-8">
                        <ft-input-editor class="v-center" [(ngModel)]="property.beCode">
                            <span class="v-center">
                                {{property.beCode}}
                                <mat-icon class="muted-text ml-1">edit</mat-icon>
                            </span>
                        </ft-input-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #destinationTmpl let-destination="destination">
    <div class="container">
        <div class="row">
            <div class="col-5">
                <div class="row my-4">
                    <span class="col-4 bold-text">
                        Name:
                    </span>
                    <ft-input-editor class="col-8" [(ngModel)]="destination.name">
                        <span class="v-center">
                            {{destination.name | ftPlaceholder: 'Please provide destination name'}}
                            <mat-icon class="muted-text ml-1">edit</mat-icon>
                        </span>
                    </ft-input-editor>
                </div>
                <div class="row my-4">
                    <span class="col-4 bold-text">
                        BE URL:
                    </span>
                    <ft-input-editor class="col-8" [(ngModel)]="destination.beUrl">
                        <span class="v-center">
                            {{destination.beUrl | ftPlaceholder: 'Custom Url is off'}}
                            <mat-icon class="muted-text ml-1">edit</mat-icon>
                        </span>
                    </ft-input-editor>
                </div>
                <div class="row my-4">
                    <span class="col-4 bold-text">
                        BE Code:
                    </span>
                    <ft-input-editor class="col-8" [(ngModel)]="destination.beCode">
                        <span class="v-center">
                            {{destination.beCode | ftPlaceholder: 'Custom code is off'}}
                            <mat-icon class="muted-text ml-1">edit</mat-icon>
                        </span>
                    </ft-input-editor>
                </div>
            </div>
            <div class="col-7">
                <div class="row my-4">
                    <span class="col-4 bold-text">
                        Greeting message:
                    </span>
                    <ft-input-editor class="col-8" [(ngModel)]="destination.greetingMessage">
                        <span class="v-center">
                            {{destination.greetingMessage | ftPlaceholder: 'Please provide greeting message'}}
                            <mat-icon class="muted-text ml-1">edit</mat-icon>
                        </span>
                    </ft-input-editor>
                </div>
                <div class="row my-1">
                    <div class="col-4">
                        <p class="bold-text">Season photos:</p>
                    </div>
                    <div class="col-8">
                        <table>
                            <tbody>
                            <tr *ngFor="let seasonPhoto of destination.seasonPhotos">
                                <td class="pr-4">
                                    {{seasonPhoto.name}}
                                </td>

                                <td>
                                    <div class="d-flex">
                                        <ft-image-preview *ngFor="let image of seasonPhoto.photos"
                                                          class="mr-1"
                                                          mode="background"
                                                          [frame]="false"
                                                          [removable]="true"
                                                          [src]="image"
                                                          [width]="'50px'"
                                                          [height]="'50px'">
                                        </ft-image-preview>
                                        <ft-dropzone-uploader-editor>
                                            <ft-upload-area width="50" height="50"></ft-upload-area>
                                        </ft-dropzone-uploader-editor>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="v-center my-4">
    <button class="ft2-btn-primary mr-1" [disabled]="seeds.length" (click)="addSection()">
        + Section
    </button>
    <button class="ft2-btn-primary mr-1" [disabled]="seeds.length" (click)="addDestination()">
        + Destination
    </button>
    <button class="ft2-btn-primary mr-1" [disabled]="seeds.length" (click)="addProperty()">
        + Property
    </button>
    <p class="ml-2" *ngIf="seeds.length">Please move created element to the mapping tree</p>
</div>

<div cdkDropList
     *ngIf="seeds?.length"
     class="ft2-tree-droppable my-4"
     (cdkDropListDropped)="drop($event)"
     [cdkDropListData]="seeds"
     [cdkDropListConnectedTo]="getSeedConnections()">
    <ft-tree-node
            *ngFor="let seed of seeds"
            (remove)="seeds = []"
            [isCollapsed]="false"
            [cdkDragData]="seeds"
            cdkDrag>
        {{seed.name}}
        <ng-template #node>
            <ng-container
                    *ngIf="seedType === 1"
                    [ngTemplateOutlet]="sectionTmpl"
                    [ngTemplateOutletContext]="{section:seed}">
            </ng-container>
            <ng-container
                    *ngIf="seedType === 2"
                    [ngTemplateOutlet]="destinationTmpl"
                    [ngTemplateOutletContext]="{destination:seed}">
            </ng-container>
            <ng-container
                    *ngIf="seedType === 3"
                    [ngTemplateOutlet]="propertyTmpl"
                    [ngTemplateOutletContext]="{property:seed}">
            </ng-container>
        </ng-template>
    </ft-tree-node>
</div>
