import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  TemplateRef,
  ContentChild,
  ViewChild,
  AfterContentInit
} from '@angular/core';
import {Subscription} from 'rxjs';
import {tap, filter} from 'rxjs/operators';
import {ModalService} from './modal.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'ft-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  inputs: [
    'id',
    'datasource',
    'hideOnDone',
    'headerText',
    'headerDescription',
    'doneText',
    'doneVisible',
    'size',
    'footerAlign',
    'cancelVisible',
    'footerVisible',
    'footerCancelText',
    'footerCancelVisible',
    'topButtonVisible',
    'topButtonText'
  ]
})
export class ModalComponent implements OnInit, OnDestroy, AfterContentInit {
  id: string;
  datasource: any;
  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() failure: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalForm', {static: false}) modalForm: NgForm;

  headerText = '';
  headerDescription = '';
  doneText = 'Done';
  doneVisible = true;
  size = 'sm';
  footerAlign = 'right';
  cancelVisible = true;
  footerVisible = true;
  footerCancelText = 'Cancel';
  footerCancelVisible = false;
  hideOnDone = true;

  sub: Subscription;
  modalData: object;
  isVisible = false;
  hide: Function;
  show: Function;

  @ContentChild(TemplateRef, {static: false})
  tmpl: TemplateRef<any>;

  @ViewChild('tc', {static: false})
  tcTmpl;

  selectedTemplate: TemplateRef<any>;

  ngOnInit() {
    this.modalService.add(this.id);
    this.sub = this.modalService.onVisibilityChanges
      .pipe(
        filter(val => val.id === this.id),
        tap(val => {
          this.modalData = typeof this.datasource == 'object' ? Object.assign({}, this.datasource) : this.datasource;
          this.isVisible = val.isVisible;
        })
      )
      .subscribe();

    this.show = this.modalService.show.bind(this.modalService, this.id);
    this.hide = this.modalService.hide.bind(this.modalService, this.id);
  }

  done() {
    this.success.emit(this.modalData);
    this.hideOnDone && this.modalService.hide(this.id);
  }

  cancel() {
    this.failure.emit();
    this.modalService.hide(this.id);
  }

  constructor(private modalService: ModalService) {}

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.modalService.remove(this.id);
  }

  ngAfterContentInit() {
    this.selectedTemplate = !!this.tmpl ? this.tmpl : this.tcTmpl;
  }
}
