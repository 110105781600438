import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
