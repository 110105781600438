import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss']
})
export class ThemingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
