import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

@Component({
    selector: 'ft-select-editor',
    templateUrl: './select-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: SelectEditorComponent
        }
    ]
})
export class SelectEditorComponent extends ModalEditorBase {
    @Input() options: any[];
    @Input() bindLabel = 'label';
    @Input() bindValue = 'value';
    @Input() searchable = false;
    @Input() placeholder: string;

    constructor() {
        super();
    }

    onModelChange(value: any) {
        this.value = value;
    }
}

