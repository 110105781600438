import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapshotFormComponent } from './components/snapshot-form/snapshot-form.component';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { PendingChangesComponent } from './components/pending-changes/pending-changes.component';


@NgModule({
    declarations: [
        SnapshotFormComponent,
        PendingChangesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LoadingModule
    ],
    exports: [
        SnapshotFormComponent,
        PendingChangesComponent
    ]
})
export class SnapshotModule {
}
