import { Directive, Input, ElementRef, Renderer2, Component, AfterContentInit } from '@angular/core';

@Directive({
    selector: '[ftAutofocus]',
})
export class AutofocusDirective implements AfterContentInit {

    constructor(private elem: ElementRef) {
    }

    ngAfterContentInit(): void {
        this.elem.nativeElement.focus();
    }

}
