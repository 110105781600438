import { NgModule } from '@angular/core';
import { IntersectionObserverComponent } from './dom/intersection-observer/intersection-observer.component';
import { IntersectionObservableComponent } from './dom/intersection-observer/intersection-observable.component';
import { BackgroundImageComponent } from './images/background-image.component';
import { MatIconModule } from '@angular/material/icon';
import { ProgressComponent } from './forms/progress/progress.component';
import { AutofocusDirective } from './dom/autofocus.directive';
import { ClickClassDirective } from './dom/click-class.directive';
import { RestrictInputDirective } from './forms/restrict-input.directive';
import { UiViewWrapperDirective } from './utils/ui-view-wrapper.directive';
import { DisabledDirective } from './dom/disabled.directive';
import { IsVisibleDirective } from './dom/is-visible.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AreasModule } from './navigation/areas/areas.module';
import { ModalModule } from './dialogs/modal/modal.module';
import { TooltipComponent } from './dialogs/tooltip/tooltip.component';
import { LoadingModule } from './forms/loading';
import { DateRangePickerModule } from './forms/date-range-picker';
import { SeasonComponent } from './components/season/season.component';
import { DownloadService } from './services/download.service';
import { FileDownloadComponent } from './forms/file-download/file-download.component';
import { PasswordToggleComponent } from './forms/password-toggle/password-toggle.component';
import { ValidationChecklistModule } from './forms/validation-checklist/validation-checklist.module';
import { FileSaverService } from './services/fileSaver.service';
import { EditorsModule } from './forms/editors/editors.module';
import { SnapshotModule } from './forms/snapshot/snapshot.module';
import { UploadersModule } from './forms/uploaders/uploaders.module';
import { ImagesModule } from './images/images.module';
import { TreeModule } from './modules/tree/tree.module';
import { PipesModule } from './utils/pipes/pipes.module';
import { PrependInputDirective } from './forms/prepend-input.directive';
import { ColorSwatchComponent } from './forms/color-swatch/color-swatch.component';
import { StickyModule } from './modules/sticky/sticky.module';


@NgModule({
    declarations: [
        IntersectionObserverComponent,
        IntersectionObservableComponent,
        ProgressComponent,
        AutofocusDirective,
        PrependInputDirective,
        ClickClassDirective,
        RestrictInputDirective,
        UiViewWrapperDirective,
        DisabledDirective,
        IsVisibleDirective,
        TooltipComponent,
        SeasonComponent,
        FileDownloadComponent,
        PasswordToggleComponent,
        ColorSwatchComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        AreasModule,
        ModalModule,
        LoadingModule,
        DateRangePickerModule,
        ValidationChecklistModule,
        MatIconModule,
        EditorsModule,
        ImagesModule,
        SnapshotModule,
        UploadersModule,
        TreeModule,
        PipesModule,
        StickyModule
    ],
    providers: [
        DownloadService,
        FileSaverService
    ],
    exports: [
        ModalModule,
        AreasModule,
        IntersectionObserverComponent,
        IntersectionObservableComponent,
        BackgroundImageComponent,
        ProgressComponent,
        AutofocusDirective,
        PrependInputDirective,
        ClickClassDirective,
        RestrictInputDirective,
        UiViewWrapperDirective,
        DisabledDirective,
        IsVisibleDirective,
        TooltipComponent,
        LoadingModule,
        DateRangePickerModule,
        FileDownloadComponent,
        SeasonComponent,
        PasswordToggleComponent,
        ValidationChecklistModule,
        EditorsModule,
        SnapshotModule,
        UploadersModule,
        ImagesModule,
        TreeModule,
        PipesModule,
        ModalModule,
        ColorSwatchComponent,
        StickyModule
    ]
})
export class SharedModule {
    constructor() {
    }
}
