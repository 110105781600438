<input type="file"
       #file
       hidden
       ng2FileSelect
       [uploader]="uploader"
       [multiple]="multiple"
       [accept]="accept"
       (error)="failure.next($event)"
       (onFileSelected)="onSelect()">
<span (click)="file.click()">
    <span *ngIf="!ref.innerHTML.trim()">
        <button [ftLoading]="uploader.isUploading" type="button" class="ft2-btn-dark -outline">
            {{buttonText}}
        </button>
    </span>
    <span #ref>
        <ng-content></ng-content>
    </span>
</span>
