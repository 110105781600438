import { Component, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { State } from "../../store/index";
import {
    changeData,
    changeText,
    loadData,
    loadDataSuccess,
    loadText,
    loadTextSuccess
} from "../../store/styleguide.actions";
import { SnapshotDataExample } from "../../models/snapshot-data.model";
import {
    selectStyleguideData,
    selectStyleguideDataLoading,
    selectStyleguideText, selectStyleguideTextLoading
} from "../../store/styleguide.selectors";
import { Observable } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";

@Component({
    selector: 'sg-snapshot',
    templateUrl: './snapshot.component.html',
    styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {
    roles = [
        {
            id: 1,
            role: 'Platform Lead',
            description: 'This person can create team members and properties and do everything an Editor can.'
        },
        {
            id: 2,
            role: 'Editor',
            description: 'This person can edit platform settings, publish content, view and edit audience personal info, and view platform stats and reports.'
        },
        {
            id: 3,
            role: 'Reviewer',
            description: 'This person can only view platform stats and reports. They can view, but not edit, other areas of the platform.'
        }
    ];
    data$: Observable<SnapshotDataExample> = this.store.pipe(select(selectStyleguideData));
    text$: Observable<any> = this.store.pipe(select(selectStyleguideText));
    changeSuccess$: Observable<SnapshotDataExample>;
    changeTextSuccess$: Observable<any>;
    loading$: Observable<boolean> = this.store.pipe(select(selectStyleguideDataLoading));
    textLoading$: Observable<boolean> = this.store.pipe(select(selectStyleguideTextLoading));

    constructor(private store: Store<State>,
                private actions$: Actions) {
        this.store.dispatch(loadData());
        this.store.dispatch(loadText());
    }

    ngOnInit(): void {
        this.changeSuccess$ = this.actions$.pipe(ofType(loadDataSuccess));
        this.changeTextSuccess$ = this.actions$.pipe(ofType(loadTextSuccess));
    }

    onFormCommit(changes) {
        this.store.dispatch(changeData({changes}));

        if (changes.hasOwnProperty('greeting')) {
            this.store.dispatch(changeText({changes}));
        }
    }
}
