<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-edit-dialog_modal p-0"
         [style.height]="height + 'px'"
         [style.width]="'768px'">
        <ngx-monaco-editor *ngIf="visible"
                           [(ngModel)]="value"
                           [required]="required"
                           #control="ngModel"
                           [options]="monacoOptions"
                           (init)="setEditor($event); formatContent();"
                           [style.height]="height + 'px'"
                           [style.max-height]="height + 'px'">
        </ngx-monaco-editor>
    </div>
</ft-modal-editor-wrapper>
