<div class="ft2-edit-dialog-trigger" *ngIf="triggerTmpl">
    <ng-container *ngTemplateOutlet="triggerTmpl"></ng-container>
</div>
<div class="ft2-edit-dialog-trigger" *ngIf="!triggerTmpl">
    <div [innerHTML]="editorBase.value | ftPlaceholder"></div>
</div>
<ng-template #dialogTmpl>
    <button class="ft2-btn-close -fixed" (click)="onClose()"></button>
    <div class="ft2-edit-dialog">
        <div class="ft2-edit-dialog_body">
            <div class="ft2-edit-dialog_header" *ngIf="editorBase.title || editorBase.titleTemplate">
                <ng-container *ngIf="!editorBase.titleTemplate">
                    {{editorBase.title}}
                </ng-container>
                <div *ngIf="editorBase.titleTemplate">
                    <ng-container *ngTemplateOutlet="editorBase.titleTemplate"></ng-container>
                </div>
            </div>
            <div class="ft2-edit-dialog_summary" *ngIf="editorBase.summary || editorBase.summaryTemplate">
                <ng-container *ngIf="!editorBase.summaryTemplate">
                    {{editorBase.summary}}
                </ng-container>
                <div *ngIf="editorBase.summaryTemplate">
                    <ng-container *ngTemplateOutlet="editorBase.summaryTemplate"></ng-container>
                </div>
            </div>
            <ng-content></ng-content>
            <div class="ft2-form-error mt-2" *ngIf="editorBase.error$ | async as error">
                <p>{{editorBase.defaultError}}</p>
            </div>
            <div class="ft2-edit-dialog_footer" *ngIf="canSave">
                <ng-content select=".ft2-edit-dialog_footer-control"></ng-content>
                <button class="ft2-btn-primary -large px-6"
                        type="submit"
                        [ftLoading]="isLoading"
                        (click)="onSave()"
                        [disabled]="editorBase.visible && editorBase.isInvalid()">
                    {{editorBase.buttonLabel}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
