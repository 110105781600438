import { Injectable } from '@angular/core';
import { EditPanelComponent } from "../components/edit-panel/edit-panel.component";

@Injectable()
export class EditPanelsService {
    private touchedPanels: EditPanelComponent[] = [];

    reset() {
        this.touchedPanels = [];
    }

    markPanelAsTouched(panel: EditPanelComponent, isMultiple: boolean) {
        if (isMultiple) {
            this.touchedPanels.push(panel);
        } else {
            this.touchedPanels = [panel];
        }
    }

    isPanelTouched(panel: EditPanelComponent) {
        return this.touchedPanels.includes(panel);
    }

    constructor() {
    }
}
