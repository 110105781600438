import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'ft-ui-view-wrapper'
})
export class UiViewWrapperDirective extends UpgradeComponent {
    constructor(elem: ElementRef, injector: Injector) {
        super('ftUiViewWrapper', elem, injector);
    }
}
