<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-edit-dialog_modal ft2-editor-list-content">
        <div class="v-center" *ngFor="let option of options; let i = index">
            <label class="ft2-checkbox">
                <input type="checkbox"
                       [required]="required"
                       (ngModelChange)="onOptionChecked($event, option)"
                       #control="ngModel"
                       [ngModel]="isOptionChecked(option)">
                <span class="ft2-checkbox_control"></span>
                <div>
                    <span class="bold-text">
                        {{option[bindLabel] ? option[bindLabel] : option}}
                    </span>
                    <p class="text-muted" *ngIf="option[bindSummary]">{{option[bindSummary]}}</p>
                </div>
            </label>
        </div>
    </div>
</ft-modal-editor-wrapper>
